import { BillItem, Order, OrderItem, ProductItem } from "../store/type";
import { Pricing, Product } from "models/Product";

import { isNil } from "lodash";
import { sha256 } from "js-sha256";

const DEFAULT_MENU_ID = "vn.han_1nx.v1";
const DEFAULT_ZONE = "han";
export const createOrderDefault = ({
  session_id,
  user_id = null,
  user_name = "",
  menu_id = DEFAULT_MENU_ID,
  table_id = "",
}) => {
  return {
    zone: DEFAULT_ZONE,
    menu_id,
    session_id,
    type: "in_store",
    user_id,
    user_name,
    note: "",
    items: [],
    discount_codes: [],
    experience_id: null,
    dedup_id: "",
    table_id,
  } as Order;
};

// QR code => vn, han, street, table => menu

const sizeTitleMapping = {
  small: "Nhỏ",
  medium: "Vừa",
  large: "Lớn",
};

export const generateCustomizeDescription = (
  item: ProductItem | BillItem | OrderItem,
  isOneSize?: boolean,
  t?: any,
) => {
  const { customizable_hot, size, customizable_ice, customizable_sugar } = item;
  const descriptions: string[] = [];
  if (size) {
    if (isOneSize) {
      descriptions.push(t?.("oneSize"));
    } else {
      descriptions.push(sizeTitleMapping[size]);
    }
  }
  if (customizable_hot) descriptions.push(t?.("hot").toLowerCase());
  if (!isNil(customizable_ice))
    descriptions.push(`${customizable_ice}% ${t?.("ice").toLowerCase()}`);
  if (!isNil(customizable_sugar))
    descriptions.push(`${customizable_sugar}% ${t?.("sugar").toLowerCase()}`);

  return descriptions.join(", ");
};

export const normalizeVietnamese = (text: string): string => {
  return text
    .normalize("NFD") // Decompose characters with diacritics
    .replace(/[\u0300-\u036f]/g, "") // Remove diacritic marks
    .replace(/đ/g, "d") // Convert `đ` to `d`
    .replace(/Đ/g, "D") // Convert `Đ` to `D`
    .toLowerCase(); // Convert to lowercase
};

export const searchVietnamese = (
  query: string,
  items: Product[],
): Product[] => {
  const normalizedQuery = normalizeVietnamese(query).toLowerCase();
  const queryTerms = normalizedQuery.split(/\s+/);
  return items?.filter?.((item) => {
    const normalizedItemInEnglish = normalizeVietnamese(
      item.name?.en,
    ).toLowerCase();
    const normalizedItemInVietnamese = normalizeVietnamese(
      item.name?.vn,
    ).toLowerCase();
    return queryTerms.every(
      (term) =>
        normalizedItemInEnglish.includes(term) ||
        normalizedItemInVietnamese.includes(term),
    );
  });
};

function normalizeItems(items: ProductItem[]): any {
  return items
    .map((item) => ({
      item_id: item.item_id,
      size: item.size,
      quantity: item.quantity,
      customizable_hot: item.customizable_hot,
      customizable_ice: item.customizable_ice,
      customizable_sugar: item.customizable_sugar,
      total_price: item.total_price,
      price: item.price,
      children: item.children ? normalizeItems(item.children) : [],
    }))
    .sort((a, b) => a.item_id.localeCompare(b.item_id));
}

export function generateDedupId(order: Order): string {
  const { session_id, items } = order;
  const normalizedItems = normalizeItems(items);

  const dataString = JSON.stringify({ session_id, items: normalizedItems });
  const hash = sha256(dataString);
  return [
    hash.substring(0, 8),
    hash.substring(8, 12),
    hash.substring(12, 16),
    hash.substring(16, 20),
    hash.substring(20, 32),
  ].join("-");
}

export const calculateItemTotalPrice = (
  items?: ProductItem[],
  allItems?: Record<string, Product>,
  isLogin?: boolean,
) => {
  if (!items) return 0;
  const totalPrice = items.reduce((acc, item) => {
    const { item_id, children, size, experience_id } = item;
    const product = allItems?.[item_id];
    const productPricing = product?.pricing.find((p) => p.size === size);
    const { value = 0, member_value = 0, exp_value = 0 } = productPricing || {};

    const totalToppingsPrice = children.reduce((acc, child) => {
      const { item_id, size } = child;
      const topping = allItems?.[item_id];
      const toppingPricing = topping?.pricing.find((p) => p.size === size);
      const {
        value = 0,
        member_value = 0,
        exp_value = 0,
      } = toppingPricing || {};
      const toppingPrice =
        (isLogin
          ? experience_id
            ? Math.min(exp_value, member_value)
            : member_value
          : value) || 0;
      return acc + toppingPrice;
    }, 0);

    const price =
      (isLogin
        ? experience_id
          ? Math.min(exp_value, member_value)
          : member_value
        : value) || 0;

    const totalItemPrice = (price + totalToppingsPrice) * item.quantity;

    return acc + totalItemPrice;
  }, 0);
  return totalPrice;
};

export const calculateDiscountedPrice = (
  isLogin: boolean,
  isFromExperience: boolean,
  pricing?: Pricing,
) => {
  if (!pricing) return 0;
  return isLogin
    ? isFromExperience
      ? Math.min(pricing?.exp_value || 0, pricing?.member_value || 0)
      : pricing?.member_value
    : pricing?.value;
};
