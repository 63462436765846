import { Gap } from "../../components/gap/Gap";
import Markdown from "react-markdown";
import PageHeader from "components/PageHeader";
import React from "react";
import { s3ImageUrl } from "utils/FileUtils";
import { useLanguage } from "hooks/useLanguage";

const markdown = `
## 1. Điều kiện áp dụng

Theo các điều khoản được quy định trong "Chính sách hoàn hủy" của Sắn Cafe, chúng tôi cam kết đảm bảo quyền lợi của khách hàng thông qua việc hỗ trợ xử lý các yêu cầu hoàn trả sản phẩm và/hoặc hoàn tiền trước thời hạn quy định.

Các điều kiện áp dụng nhằm tạo sự minh bạch và hỗ trợ khách hàng trong việc giải quyết các xung đột có thể phát sinh trong quá trình giao dịch. Sắn Cafe và khách hàng có thể liên hệ để thỏa thuận giải quyết tranh chấp một cách phù hợp.

## 2. Điều kiện trả hàng

Khách hàng có thể yêu cầu hoàn trả sản phẩm hoặc hoàn tiền trong các trường hợp sau:

- **Sản phẩm không được giao đầy đủ:** Khách hàng đã thanh toán nhưng không nhận được sản phẩm hoặc không nhận được toàn bộ các sản phẩm đã đặt.
- **Sản phẩm bị lỗi hoặc hư hại:** Sản phẩm bị lỗi kỹ thuật hoặc bị hư hại trong quá trình vận chuyển trước khi đến tay khách hàng.
- **Giao sai sản phẩm:** Sản phẩm nhận được không đúng với đơn đặt hàng (sai kích cỡ, màu sắc, v.v.).
- **Thông tin không chính xác:** Sản phẩm khác biệt rõ rệt so với thông tin được cung cấp trong mô tả sản phẩm.
- **Không thuộc danh mục hạn chế trả hàng:** Sản phẩm không nằm trong danh sách sản phẩm không áp dụng trả hàng.
- **Thỏa thuận trả hàng:** Sắn Cafe đã đồng ý với yêu cầu trả hàng từ khách hàng.

## 3. Cách thức hủy đơn và hoàn tiền

### 3.1 Phương thức hủy đơn

Khách hàng có thể hủy đơn hàng bằng các cách sau:

1. **Hủy trực tiếp trên hệ thống:** Tiến hành hủy đơn thông qua website chính thức của Sắn Cafe.
2. **Qua các kênh liên lạc khác:** Gửi yêu cầu hủy đơn qua các kênh của Sắn Cafe như Fanpage hoặc Zalo.
3. **Hủy với đối tác giao nhận:** Chủ động làm việc với đơn vị giao nhận để hủy đơn hàng.

### 3.2 Thời gian hoàn tiền

#### Đối với đơn hàng chưa nhận hàng:
- **Thanh toán qua ví điện tử:** Hoàn tiền trong vòng **24 giờ** kể từ khi yêu cầu hủy đơn được xác nhận.
- **Thanh toán qua chuyển khoản:** Hoàn tiền trong vòng **7 ngày làm việc** kể từ khi yêu cầu hủy đơn được xác nhận.

#### Đối với đơn hàng đã từ chối nhận hoặc hoàn trả:
- **Thanh toán qua ví điện tử/ngân hàng:** Sau khi công ty nhận được sản phẩm hoàn trả từ đơn vị vận chuyển, tiền sẽ được hoàn trong vòng **7 ngày làm việc** thông qua tài khoản ngân hàng hoặc ví điện tử mà khách hàng cung cấp.
- **Đơn hàng COD:** Nếu khách hàng từ chối nhận hàng, tiền mặt sẽ không được thu, và đơn hàng sẽ được xử lý hoàn trả trực tiếp.

## 4. Liên hệ hỗ trợ

Mọi thắc mắc hoặc yêu cầu liên quan đến hoàn hủy, vui lòng liên hệ:

- **Công ty TNHH Sắn Cafe**  

- **Địa chỉ:** Căn SH36, Khu nhà ở Rue de Charme, Dự án Khu đô thị mới Hạ Đình, Ngõ 214
Nguyễn Xiển, Xã Tân Triều, Huyện Thanh Trì, Thành phố Hà Nội, Việt Nam

- **Email:** contact@san.cafe

- **Website:** [san.cafe](https://san.cafe)

- **Hotline:** 1900 8643

Chúng tôi luôn sẵn sàng hỗ trợ và đảm bảo quyền lợi tốt nhất cho khách hàng.

`;

const ReturnPolicyPage: React.FC = () => {
  const { t } = useLanguage();
  return (
    <div className={`flex h-full sm:h-screen w-full flex-col items-center pb-20`}>
      <div
        style={{ height: "calc(100% - 64px)" }}
        className="relative h-full w-full max-w-screen-md overflow-visible"
      >
        <img
          src={s3ImageUrl("bg_san_no_logo.webp")}
          alt=""
          className={`z-0 h-full w-full`}
        />
        <div
          className="absolute min-h-[320%] sm:min-h-[70%] bottom-0 left-0 right-0 top-0 z-0"
        >
          <PageHeader transparent title={t("returnPolicy")} />
          <div
            style={{ height: "calc(100% - 64px)" }}
            className="mx-4 mt-20 flex h-full flex-col items-center rounded-t-2xl bg-white p-4 md:mx-8"
          >
            <Gap size={"S"} />
            <img
              src={s3ImageUrl("san-logo.png")}
              alt=""
              className="h-12 w-12"
            />
            <Markdown
              components={{
                h1(props) {
                  const { node, ...rest } = props;
                  return (
                    <h1 className={`text-3xl my-3 text-brand-4`} {...rest} />
                  );
                },
                h2(props) {
                  const { node, ...rest } = props;
                  return (
                    <h2 className={`my-1 text-18px text-brand-4`} {...rest} />
                  );
                },
                h3(props) {
                  const { node, ...rest } = props;
                  return (
                    <h3 className={`text-lg my-2 text-brand-4`} {...rest} />
                  );
                },
                ul(props) {
                  const { node, ...rest } = props;
                  return (
                    <span
                      style={{ color: "#31261D" }}
                      className="text-justify text-[14px]"
                      {...rest}
                    />
                  );
                },
                p(props) {
                  const { node, ...rest } = props;
                  return (
                    <span
                      style={{ color: "#31261D" }}
                      className="text-justify text-[14px]"
                      {...rest}
                    />
                  );
                },
                a(props) {
                  const { node, ...rest } = props;
                  return props.href?.includes("@") ? (
                    <a
                      href={`mailto:${props.href}`}
                      style={{ color: "deepskyblue" }}
                      {...rest}
                    />
                  ) : (
                    <a
                      href={`${props.href}`}
                      style={{ color: "deepskyblue" }}
                      {...rest}
                    />
                  );
                },
              }}
              className={`mt-6 text-brand-4 xl:mt-8`}
            >
              {markdown}
            </Markdown>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReturnPolicyPage;
