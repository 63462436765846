import { FLAGS } from "helpers/constants";
import { SanCoin } from "components/SanCoin";
import { useLanguage } from "hooks/useLanguage";
import { useRewardPoint } from "hooks/useRewardPoint";

export const useProfileItem = () => {
  const { t } = useLanguage();

  const totalPoints = useRewardPoint();

  const rewardPointMenu = {
    id: 3,
    name: t("earnPoints"),
    path: "/rewards",
    icon: "san_medal.png",
    shortName: t("earnPoints"),
    rightNumber: +totalPoints,
    RightIcon: SanCoin,
  };
  const orderHistoryMenu = {
    id: 4,
    name: t("orderHistory"),
    path: "/order-history",
    icon: "profile_history.png",
    shortName: t("order"),
    rightNumber: undefined,
    RightIcon: null,
  };
  const favoriteMenu = {
    id: 1,
    name: t("favorite"),
    path: "/favorite",
    icon: "profile_favourite.png",
    shortName: t("favorite"),
    rightNumber: undefined,
    RightIcon: null,
  };
  const profileMenus = [
    favoriteMenu,
    orderHistoryMenu,
    FLAGS.ENABLE_REWARD_POINT ? rewardPointMenu : undefined,
  ].filter(Boolean);

  const homeUtilities = [favoriteMenu, orderHistoryMenu];

  // {
  //   id: 2,
  //   name: t("discount"),
  //   path: "/discount",
  //   icon: "profile_voucher.png",
  //   shortName: t("promotion"),
  // },
  // {
  //   id: 5,
  //   name: t("store"),
  //   path: "/store",
  //   icon: "profile_store.png",
  //   shortName: t("store"),
  // },

  const generalMenus = [
    {
      id: 2,
      name: t("policy"),
      path: "/policy",
      icon: "profile_setting.png",
      subMenus: [
        {
          id: 1,
          name: t("securePolicy"),
          path: "/privacy-policy",
        },
        {
          id: 3,
          name: t("returnPolicy"),
          path: "/return-policy",
        },
        {
          id: 4,
          name: t("shippingPolicy"),
          path: "/shipping-policy",
        },
      ],
    },
    {
      id: 3,
      name: t("vnpayGuide"),
      path: "/vnpay-guide",
      icon: "profile_setting.png",
    },
    {
      id: 4,
      name: t("aboutUs"),
      path: "/about",
      icon: "profile_setting.png",
    },
    {
      id: 1,
      name: t("language"),
      // path: "/language",
      icon: "profile_setting.png",
      label: "Tiếng Việt",
    },
  ];

  return {
    profileMenus,
    generalMenus,
    homeUtilities,
    rewardPointMenu,
  };
};
