export const S3_BASE_URL =
  "https://dev-pub-item-images.s3.ap-southeast-1.amazonaws.com";
export const s3ImageUrl = (imageName: string, domain: string = "ui") => {
  return `${S3_BASE_URL}/${domain}/${imageName}`;
};

export const R2_BUCKET_URL =
  "https://cors-anywhere.herokuapp.com/https://pub-b45229b3c3c342b1bc12b1c85bdcf8f8.r2.dev/";

export const r2ImageUrl = (path: string) => `${R2_BUCKET_URL}${path}`;

export const imageWithTimestamp = (imageName: string) => {
  return `${imageName}?timestamp=${Date.now()}`;
};
