import "react-spring-bottom-sheet/dist/style.css";

import { DELIVERY_METHOD, LOCAL_STORAGE_KEYS } from "helpers/constants";
import { useAppDispatch, useAppSelector } from "../../store/hook";

import { Gap } from "../gap/Gap";
import IconRadio from "assets/icons/radio-icon";
import { isNil } from "lodash";
import { s3ImageUrl } from "../../utils/FileUtils";
import { setDeliveryMethod } from "../../store/orderSlice";
import { useAuthContext } from "contexts/AuthContext";
import { useLanguage } from "../../hooks/useLanguage";
import { useNavigate } from "react-router-dom";

export interface AddressSectionProps {
  className?: string;
  isCheckout?: boolean;
}

export default function AddressSection(props: AddressSectionProps) {
  const { isCheckout = false } = props;
  const { t } = useLanguage();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const tableId = localStorage.getItem(LOCAL_STORAGE_KEYS.TABLE_ID);
  const address = localStorage.getItem("address");
  const deliveryMethod = useAppSelector((state) => state.order.deliveryMethod);

  const { isAuthenticated: isLogin } = useAuthContext();

  const deliveryMethods = [
    {
      id: 1,
      name: tableId ? `${t("table")} ${tableId}` : t("inStore"),
      buttonName: tableId
        ? isCheckout
          ? ``
          : `${t("changeTable")}`
        : t("selectTable"),
      logo: "shop-1.png",
      type: DELIVERY_METHOD.IN_STORE,
      enableAction: isNil(tableId),
      url: "/select-table",
      available: !isCheckout || deliveryMethod === DELIVERY_METHOD.IN_STORE,
      note: null,
    },
    {
      id: 2,
      name: isLogin ? t("freeShipping") : t("delivery"),
      buttonName: isLogin
        ? address
          ? address
          : t("selectAddress")
        : t("loginToSelectAddress"),
      logo: "delivery-1.png",
      type: DELIVERY_METHOD.DELIVERY,
      enableAction: isNil(address),
      url: isLogin ? "/select-address" : "/login",
      available: !isCheckout || deliveryMethod === DELIVERY_METHOD.DELIVERY,
      note: "*Khu vực áp dụng (freeship đơn hàng trên 100k): Văn Quán, Hạ Đình, Thanh Xuân Nam, Khương Đình, Thanh Xuân Bắc, Tân Triều",
    },
  ];

  return (
    <div
      className={`flex flex-col rounded-lg bg-white p-4 ${props.className || ""}`}
    >
      <div>
        <p className={`text-16px font-bold text-brand-4`}>
          {t("deliveryMethod")}
        </p>
      </div>
      {deliveryMethods.map((item, index) => {
        return (
          item &&
          item.available && (
            <div
              key={index}
              className={`flex min-h-10 flex-row items-start justify-between pt-3`}
              onClick={() => {
                dispatch(setDeliveryMethod(item.type));
              }}
            >
              <div className="flex w-full flex-row items-start pr-2">
                <img
                  className={`h-8 w-8 rounded-md`}
                  src={s3ImageUrl(item.logo)}
                  alt=""
                />
                <div className="-mt-1 flex flex-col">
                  <span
                    className={`ml-2 whitespace-pre-line text-14px font-semibold text-brand-4`}
                  >
                    {item.name}
                  </span>
                  {deliveryMethod === item.type && (
                    <div className="flex flex-col items-start">
                      <span
                        className={`-mt-1 ml-2 cursor-pointer whitespace-pre-line text-12px font-normal text-brand`}
                        onClick={() => {
                          if (!item.available || isCheckout) {
                            return;
                          }
                          dispatch(setDeliveryMethod(item.type));
                          navigate(item.url, {
                            state: {
                              tableId: tableId,
                              address: address,
                              source: "/select-address",
                            },
                          });
                        }}
                      >
                        {item.buttonName}
                      </span>
                    </div>
                  )}
                  {item.note &&
                    !address &&
                    deliveryMethod === DELIVERY_METHOD.DELIVERY && (
                      <span className="ml-2 text-[9px] font-normal text-brand-4">
                        {item.note}
                      </span>
                    )}
                </div>
              </div>
              {!isCheckout && (
                <div className="flex h-full min-w-6 items-start">
                  <IconRadio $isActive={deliveryMethod === item.type} />
                </div>
              )}
            </div>
          )
        );
      })}
    </div>
  );
}
