import { BillItem } from "store/type";
import { formatCurrency } from "../../utils/ConcurencyUtils";
import { generateCustomizeDescription } from "utils/OrderUtils";
import { imageWithTimestamp } from "utils/FileUtils";
import { useAppSelector } from "../../store/hook";
import { useAuthContext } from "contexts/AuthContext";
import { useLanguage } from "hooks/useLanguage";
import { useMenuData } from "hooks/useMenuData";

export interface OrderItemProps {
  item: BillItem;
  showDivide: boolean;
}

export default function MyBillItem(props: OrderItemProps) {
  const { item, showDivide } = props || {};
  const {} = useMenuData();
  const { t } = useLanguage();
  const {
    price,
    quantity,
    item_id,
    item_name,
    children,
    discount_price,
    discount_pct,
  } = item || {};
  const { isAuthenticated: isLogin } = useAuthContext();
  const allItems = useAppSelector((root) => root.allItems.allItems);
  const itemInMenu = allItems?.[item_id];
  const isOneSize = itemInMenu?.pricing?.length === 1;
  const description = generateCustomizeDescription(item, isOneSize, t);
  const toppings = children.map((item) => item.item_name).join("\n• ");

  const toppingsPrice = children.reduce((acc, item) => {
    const { item_id } = item;
    const fullInfoItem = allItems?.[item_id];
    const selectedSize = fullInfoItem?.pricing?.find?.(
      (item) => item.size === "small",
    );
    const price =
      (isLogin ? selectedSize?.member_value : selectedSize?.value) || 0;
    return acc + price;
  }, 0);

  const originalToppingsPrice = children.reduce((acc, item) => {
    const { item_id } = item;
    const fullInfoItem = allItems?.[item_id];
    const selectedSize = fullInfoItem?.pricing?.find?.(
      (item) => item.size === "small",
    );
    const price = selectedSize?.value;
    return acc + price;
  }, 0);

  const itemPrice = isLogin ? discount_price : price;

  const discountBgColorMap = {
    10: "bg-brand-2",
    20: "bg-brand-tint",
    30: "bg-brand-tint",
  };

  return (
    <div className="mt-2 w-full">
      <div className={`flex w-full flex-row items-start justify-between`}>
        <div className={`relative`}>
          <img
            src={imageWithTimestamp(itemInMenu?.image || "")}
            alt=""
            className={`mt-1 h-14 w-14 min-w-14 rounded-lg`}
          />
          {discount_pct > 0 && (
            <div
              className={`absolute -bottom-0 left-0 right-0 flex h-4 items-center justify-center rounded-lg ${
                discountBgColorMap[discount_pct]
              }`}
            >
              <div className={`text-12px font-semibold text-white`}>{`-${
                discount_pct
              }%`}</div>
            </div>
          )}
        </div>
        <div
          className={`ml-2 flex min-h-14 w-full flex-col items-start justify-start`}
        >
          <div className="flex w-auto flex-row justify-between gap-2">
            <p className={`line-clamp-1 text-14px font-normal text-brand-4`}>
              {`${item_name} `}
            </p>
          </div>
          <p className={`text-12px font-normal text-tertiary`}>{description}</p>
          {children?.length > 0 && (
            <p
              className={`whitespace-pre-line text-12px font-normal text-tertiary`}
            >
              {`• ${toppings}`}
            </p>
          )}
        </div>
        <div className="flex flex-col items-end">
          <div>
            <p className="text-14px font-normal text-tertiary">{`${formatCurrency(itemPrice + toppingsPrice)}`}</p>
            {isLogin && (
              <p className="text-end text-[10px] font-normal text-tertiary line-through">{`${formatCurrency(price + originalToppingsPrice)}`}</p>
            )}
          </div>
          <p className="text-[12px] font-normal text-brand-1">{`x ${quantity}`}</p>
          <p className="text-14px font-normal text-brand-1">{`${formatCurrency((itemPrice + toppingsPrice) * quantity)}`}</p>
        </div>
      </div>
      {showDivide && <div className={`mt-2 h-[1px] w-full bg-[#FAFAFA]`} />}
    </div>
  );
}
