import { useLocation, useNavigate } from "react-router-dom";

import { Gap } from "components/gap/Gap";
import IconRadio from "assets/icons/radio-icon";
import { LOCAL_STORAGE_KEYS } from "helpers/constants";
import PageHeader from "components/PageHeader";
import SanButton from "components/SanButton";
import { isEmpty } from "lodash";
import { useLanguage } from "hooks/useLanguage";
import { useState } from "react";

export type SelectTableProps = {
  className?: string;
};

export default function SelectTable(props: SelectTableProps) {
  const { t } = useLanguage();
  const navigate = useNavigate();
  const { tableId = "" } = useLocation().state || {};
  const [selectedTable, setSelectedTable] = useState<string>(tableId);

  const tables = [
    {
      id: 1,
      area: "Sân trước",
      tableInAreas: [
        {
          id: 1,
          name: "T01",
        },
        {
          id: 2,
          name: "T02",
        },
        {
          id: 3,
          name: "T03",
        },
      ],
    },
    {
      id: 2,
      area: "Sân sau",
      tableInAreas: [
        {
          id: 1,
          name: "S01",
        },
        {
          id: 2,
          name: "S02",
        },
        {
          id: 3,
          name: "S03",
        },
      ],
    },
    {
      id: 3,
      area: "Trong nhà",
      tableInAreas: Array.from({ length: 14 }, (_, index) => ({
        id: index + 1,
        name: `${index + 1}`.padStart(2, "0"),
      })),
    },
  ];

  return (
    <div>
      <div className="mx-auto flex max-w-screen-sm flex-col">
        <PageHeader title={t("selectTable")} />
        <div className="flex flex-col px-4">
          <div className="flex flex-col">
            {tables.map((table) => (
              <div key={table.id} className="mt-4 flex flex-col">
                <p className="text-16px font-normal text-brand-4">
                  {table.area}
                </p>
                <div className="grid grid-cols-3 gap-2">
                  {table.tableInAreas.map((tableInArea) => (
                    <div
                      key={tableInArea.id}
                      className="flex flex-row items-center"
                      onClick={() => setSelectedTable(tableInArea.name)}
                    >
                      <IconRadio
                        $isActive={selectedTable === tableInArea.name}
                      />
                      <span
                        className={`ml-2 text-16px ${
                          selectedTable === tableInArea.name
                            ? "font-bold"
                            : "font-normal"
                        } text-brand-4 `}
                      >
                        {tableInArea.name}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <Gap size="M1" />
          <SanButton
            title={t("confirm")}
            disabled={isEmpty(selectedTable)}
            className={`${isEmpty(selectedTable) ? "bg-tertiary hover:bg-tertiary focus:bg-tertiary" : ""}`}
            onClick={() => {
              localStorage.setItem(LOCAL_STORAGE_KEYS.TABLE_ID, selectedTable);
              localStorage.setItem(
                LOCAL_STORAGE_KEYS.TABLE_TIMESTAMP,
                Date.now().toString(),
              );
              navigate(-1);
            }}
          />
        </div>
      </div>
    </div>
  );
}
