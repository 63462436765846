import { s3ImageUrl } from "utils/FileUtils";

export type SanCoinProps = {
  className?: string;
  size?: number;
};

export const SanCoin = ({ className, size = 16 }: SanCoinProps) => {
  return (
    <div
      style={{
        width: size,
        height: size,
      }}
      className={`flex flex-row items-center justify-center rounded-full
         border-[1.5px] border-solid border-white bg-brand ${className}`}
    >
      <img
        style={{
          width: size - 6,
          height: size - 6,
        }}
        src={s3ImageUrl("FAVICON.png")}
        alt=""
        className="rounded-full object-center"
      />
    </div>
  );
};
