import ArrowRight from "assets/icons/arrow-right";
import BannerSection from "components/home-components/BannerSection";
import { Gap } from "components/gap/Gap";
import React from "react";
import { s3ImageUrl } from "utils/FileUtils";
import { useAuthContext } from "contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { useSaveTableFromUrl } from "hooks/useSaveTableFromUrl";
const WelcomePage: React.FC = () => {
  const navigate = useNavigate();
  useSaveTableFromUrl();

  const { isAuthenticated: isLogin } = useAuthContext();

  const banners = [
    {
      title: "Sắn Trải Nghiệm",
      image: s3ImageUrl("san_tarot.webp"),
      subTitle: "AI gợi ý đồ uống",
      description:
        "Chọn những lá Tarot, giải mã thông điệp và khám phá đồ uống từ Sắn AI.",
      buttonTitle: "Trải nghiệm ngay",
      href: "/tarot",
      note: "Chỉ từ 2 - 3 phút",
    },
    {
      title: "Sắn Cafe",
      image: s3ImageUrl("san_cafe.webp"),
      subTitle: "Đánh thức vị giác",
      description:
        "Đắm mình trong hương vị café đậm đà, hảo hạng, Sắn café đánh thức các giác quan và khơi nguồn sáng tạo trong bạn.",
      buttonTitle: "Order ngay",
      href: "/home",
    },
  ];

  return (
    <div className="flex h-screen w-full flex-col items-center justify-center bg-brand-shape-4">
      <div className="flex h-screen w-screen max-w-screen-md flex-col items-center justify-start bg-brand-shape-4">
        <Gap size={"S"} />
        <div className="w-full px-4">
          <BannerSection borderRadius={12} isShowLogo={true} />
        </div>
        <div className="flex w-full flex-col items-center justify-center gap-2 px-4">
          {/* <img
            src={s3ImageUrl("san-logo.png")}
            className="h-10 w-10"
            alt="logo"
          /> */}
          <h2 className="my-2 whitespace-pre-line text-center font-space text-[20px] font-bold">
            {`Hôm nay bạn đến Sắn để \n"Trải nghiệm" hay "Thưởng thức"?`}
          </h2>
          {banners.map((banner, index) => {
            return (
              <div
                key={index}
                className="flex w-full flex-row items-center justify-center rounded-lg bg-brand-3 px-4 py-3"
                onClick={() => {
                  navigate(banner.href);
                }}
              >
                <div className="flex w-full flex-col pr-4">
                  <span className="my-1 text-[15px] font-bold leading-[20px] text-brand">
                    {banner.title}
                  </span>
                  <div className="flex w-full flex-col items-start justify-center">
                    <span className="text-[13px] font-bold text-brand-4">
                      {banner.subTitle}
                    </span>
                    <span className="mt-1 text-left text-[11px] text-gray-menu">
                      {banner.description}
                    </span>
                    <Gap size={"S"} />
                    <a
                      href={banner.href}
                      className="flex flex-row items-center justify-center gap-2 font-semibold text-brand"
                    >
                      <span>{banner.buttonTitle}</span>
                      <div className="mt-[1px]">
                        <ArrowRight />
                      </div>
                    </a>
                    <span className="-mt-0.5 text-[9px] text-gray-menu">
                      {banner.note}
                    </span>
                  </div>
                </div>
                <img
                  src={banner.image}
                  className="h-28 w-28 rounded-full object-cover"
                  alt=""
                />
              </div>
            );
          })}
        </div>
        <Gap size={"M"} />
        {!isLogin && (
          <div className="flex w-full flex-col items-center justify-center">
            <span
              onClick={() => {
                navigate("/login");
              }}
              className="flex flex-row items-center justify-center gap-1 text-[12px] font-semibold text-brand"
            >
              Đăng nhập
              <span className="text-[12px] font-normal text-gray-menu">
                siêu nhanh với OTP – nhận ưu đãi đến 30% ngay!
              </span>
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default WelcomePage;
