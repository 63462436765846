import "react-spring-bottom-sheet/dist/style.css";

import { FLAGS, PAYMENT_METHOD } from "helpers/constants";
import { setPaymentMethod, setSelectedVNPayUrl } from "store/orderSlice";
import { useAppDispatch, useAppSelector } from "../../store/hook";

import { BottomSheet } from "react-spring-bottom-sheet";
import ChevronRight from "assets/icons/chevron-right";
import { Gap } from "../gap/Gap";
import IconRadio from "assets/icons/radio-icon";
import { isEmpty } from "lodash";
import { s3ImageUrl } from "../../utils/FileUtils";
import { useLanguage } from "../../hooks/useLanguage";
import { useState } from "react";

export interface PaymentMethodSectionProps {
  className?: string;
  shouldShowSelector?: boolean;
  vnp_urls?: Record<string, string>;
}

export default function PaymentMethodSection(props: PaymentMethodSectionProps) {
  const { t } = useLanguage();
  const [open, setOpen] = useState(false);
  const paymentMethod = useAppSelector((root) => root.order.paymentMethod);

  const dispatch = useAppDispatch();
  const { shouldShowSelector = true } = props;
  const selectedVNPayUrlId =
    useAppSelector((root) => root.order.selectedVNPayUrlId) || "app";

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string>(
    selectedVNPayUrlId || "app",
  );

  const nestedPaymentMethodName = {
    app: "Chuyển khoản qua app ngân hàng",
    int_card: "Thẻ thanh toán quốc tế",
    qr: "Quét mã QR",
    default: "Cổng thanh toán VNPay",
    local_card: "Thẻ thanh toán nội địa",
  };

  const nestedPaymentLogo = {
    app: "app-1.png",
    int_card: "int-card-1.png",
    qr: "qr-code-1.png",
    default: "vn-pay-1.png",
    local_card: "local-card-1.png",
  };

  const nestPaymentLogoSizes = {
    default: "h-8 w-8",
    app: "h-6 w-6",
    qr: "h-6 w-6",
    local_card: "h-7 w-7",
    int_card: "h-8 w-8",
  };

  const paymentMethodLogoSizes = {
    [PAYMENT_METHOD.VNPAY]: "h-7 w-7",
    [PAYMENT_METHOD.CASH]: "h-8 w-8",
  };

  const nestedPaymentMethods = Object.keys(nestedPaymentMethodName || {}).map(
    (key) => ({
      id: key,
      name: nestedPaymentMethodName[key],
      logo: nestedPaymentLogo[key],
      type: key,
      logoSize: nestPaymentLogoSizes[key],
      url: props.vnp_urls?.[key],
    }),
  );

  const paymentMethods = [
    FLAGS.ENABLE_VNPAY && {
      id: 1,
      name: "Chuyển khoản / Thẻ ngân hàng",
      logo: "app-1.png",
      type: PAYMENT_METHOD.VNPAY,
    },
    {
      id: 2,
      name: t("cash"),
      logo: "cash-1.png",
      type: PAYMENT_METHOD.CASH,
    },
  ];

  return (
    <div
      className={`flex flex-col rounded-lg bg-white p-4 pb-2 ${props.className || ""}`}
    >
      <div>
        <p className={`text-16px font-bold text-brand-4`}>
          {t("paymentMethod")}
        </p>
      </div>
      <Gap size={"XS"} />
      {paymentMethods.map((item, index) => {
        if (!shouldShowSelector && paymentMethod === PAYMENT_METHOD.VNPAY) {
          return null;
        }
        if (!shouldShowSelector && item && paymentMethod !== item.type) {
          return null;
        }
        return (
          item && (
            <div className={`flex flex-col`}>
              <div
                key={index}
                className={`flex flex-row items-center justify-between py-2`}
                onClick={() => {
                  dispatch(setPaymentMethod(item.type));
                }}
              >
                <div className="flex flex-row items-center">
                  <div className="flex h-8 w-8 items-center justify-center">
                    <img
                      className={`${paymentMethodLogoSizes[item.type]}`}
                      src={s3ImageUrl(item.logo)}
                      alt=""
                    />
                  </div>
                  <p className={`ml-2 text-14px font-normal text-gray-menu`}>
                    {item.name}
                  </p>
                </div>
                {shouldShowSelector && (
                  <IconRadio $isActive={paymentMethod === item.type} />
                )}
              </div>
            </div>
          )
        );
      })}
      <div className={`flex w-full flex-col items-start`}>
        {!isEmpty(props.vnp_urls) && paymentMethod === PAYMENT_METHOD.VNPAY && (
          <div
            onClick={() => {
              setOpen(true);
            }}
            className={`mb-2 flex w-full flex-row items-center justify-between`}
          >
            <div className="flex w-full flex-row items-center">
              <div className="flex h-8 w-8 items-center justify-center">
                <img
                  className={`${paymentMethodLogoSizes[paymentMethod]} object-contain`}
                  src={s3ImageUrl(nestedPaymentLogo[selectedVNPayUrlId])}
                  alt=""
                />
              </div>
              <p className={`ml-2 text-14px font-normal text-gray-menu`}>
                {nestedPaymentMethodName[selectedPaymentMethod]}
              </p>
            </div>
            <div
              className={
                "flex w-20 cursor-pointer flex-row items-end justify-end"
              }
            >
              <span className="text-14px font-semibold text-brand">
                {t("change")}
              </span>
            </div>
          </div>
        )}
      </div>

      <BottomSheet
        open={open}
        expandOnContentDrag
        onDismiss={() => {
          setOpen(false);
        }}
      >
        <div className={`b g-red-200 flex h-fit w-full flex-col px-2`}>
          <p className={`text-center text-16px font-bold text-brand-4`}>
            {t("paymentMethod")}
          </p>
          <Gap size={"M"} />
          {nestedPaymentMethods.map((item, index) => (
            <div
              key={index}
              className={`flex max-h-12 min-h-12 flex-row items-center justify-start px-4 py-2
                ${item.id === selectedPaymentMethod ? "rounded-[16px] border-[1px] border-primary" : "rounded-[16px] border-[1px] border-transparent"}`}
              onClick={() => {
                setSelectedPaymentMethod(item.id);
              }}
            >
              <img
                className={`${item.logoSize} min-w-8 object-contain`}
                src={s3ImageUrl(item.logo)}
                alt=""
              />
              <p className={`ml-2 text-14px font-normal text-gray-menu`}>
                {item.name}
              </p>
            </div>
          ))}
          <Gap size={"M"} />
          <div className={`w-full px-2 pb-4`}>
            <button
              className={`w-full rounded-[40px] bg-brand-1 px-8 py-[6px]`}
              onClick={() => {
                setOpen(false);
                dispatch(setSelectedVNPayUrl(selectedPaymentMethod));
              }}
            >
              <p className={`text-14px font-semibold text-white`}>
                {t("agree")}
              </p>
            </button>
          </div>
        </div>
      </BottomSheet>
    </div>
  );
}
