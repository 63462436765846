import { BillItem, OrderItem, ProductItem } from "store/type";
import { useEffect, useState } from "react";
import {
  useLazyQueryBillsQuery,
  useLazyQueryOrdersQuery,
} from "store/api/consumerApi";

import { Gap } from "components/gap/Gap";
import Lottie from "lottie-react";
import MyBillItem from "components/order/MyBillItem";
import OrderHistoryItem from "./OrderHistoryItem";
import { OrderHistoryItemSkeleton } from "./OrderHistoryItemSkeleton";
import PageHeader from "components/PageHeader";
import SanButton from "components/SanButton";
import { check } from "prettier";
import { formatCurrency } from "utils/ConcurencyUtils";
import moment from "moment";
import { useLanguage } from "hooks/useLanguage";
import { useNavigate } from "react-router-dom";

export type OrderHistoryPageProps = {};

export function OrderHistoryPage(props: OrderHistoryPageProps) {
  const { t } = useLanguage();
  const navigate = useNavigate();
  const orderHistoryTabs = [
    {
      id: "paid",
      title: t("processing"),
      status: ["paid", "checked_out_cash", "delivered"],
    },
    {
      id: "fulfilled",
      title: t("fulfilled"),
      status: ["fulfilled"],
    },
    {
      id: "cancelled",
      title: t("cancelled"),
      status: ["cancelled"],
    },
  ];
  const [getOrders, { data: ordersResult, isFetching: isFetchingOrders }] =
    useLazyQueryOrdersQuery();
  const orders = ordersResult?.result?.orders;

  const [activeTab, setActiveTab] = useState(orderHistoryTabs[0].id);

  const [ordersShouldShowFull, setOrdersShouldShowFull] = useState<number[]>(
    [],
  );

  useEffect(() => {
    // getBills({});
    getOrders({
      status: orderHistoryTabs.find((tab) => tab.id === activeTab)?.status,
    });
  }, []);

  const onChangeTab = (tabId: string) => {
    setActiveTab(tabId);
    getOrders({
      status: orderHistoryTabs.find((tab) => tab.id === tabId)?.status,
    });
  };

  const orderStatusTitleByStatus = {
    paid: "Đã thanh toán",
    checked_out_cash: "Chờ thanh toán",
    fulfilled: "Đã hoàn tất",
    cancelled: "Đã huỷ",
    none: "",
  };

  const orderStatusColorByStatus = {
    paid: "text-blue-500",
    checked_out_cash: "text-brand-yellow-1",
    fulfilled: "brand-green-1",
    cancelled: "text-brand-4",
    none: "text-brand-4",
  };

  return (
    <div className="flex h-full w-full flex-col items-center bg-default-1">
      <PageHeader title={t("orderHistory")} />
      <Gap size={"S"} />

      <div className="h-full w-full max-w-screen-md bg-default-1 px-4">
        <div className="h-full w-full">
          <div className="flex flex-row justify-between rounded-[40px] bg-neutrals-divider px-[2px] py-[2px]">
            {orderHistoryTabs.map((tab) => {
              const isActive = tab.id === activeTab;
              return (
                <button
                  key={tab.id}
                  onClick={() => onChangeTab(tab.id)}
                  className={`relative w-1/3 rounded-[40px] px-0 py-2 transition-colors duration-300 ${
                    isActive
                      ? " border-[1px] border-solid border-gray-c5 bg-white"
                      : ""
                  }`}
                >
                  <span
                    className={`line-clamp-1 font-inter text-[14px] ${isActive ? "text-brand-4" : "font-normal text-gray-menu"}`}
                  >
                    {tab.title}
                  </span>
                </button>
              );
            })}
          </div>
        </div>
        <Gap size={"S"} />
        <div className="min-h-screen w-full bg-default-1">
          {isFetchingOrders ? (
            <OrderHistoryItemSkeleton />
          ) : (
            orders?.map((order) => (
              <div
                key={order.id}
                className="mb-4 w-full rounded-lg bg-white px-2 py-2"
              >
                <div className="flex flex-row justify-between">
                  <div>
                    <span className="font-inter text-[14px] font-semibold text-brand-4">{`#${order?.id}`}</span>
                  </div>
                  <span
                    className={`text-[14px] ${orderStatusColorByStatus[order?.status || "none"]} font-semibold`}
                  >{`${orderStatusTitleByStatus[order?.status || "none"]}`}</span>
                </div>
                {order?.items
                  ?.slice(
                    0,
                    order?.id && ordersShouldShowFull.includes(order?.id)
                      ? 100
                      : 2,
                  )
                  ?.map((item: ProductItem, index: number) => (
                    <OrderHistoryItem
                      key={index}
                      item={item as unknown as OrderItem}
                      showDivide={
                        index === 0 && (order?.items?.length || 0) > 1
                      }
                    />
                  ))}
                <div className="flex flex-row justify-between pl-2 pt-2">
                  {(order?.items?.length || 0) > 2 &&
                  order?.id &&
                  !ordersShouldShowFull.includes(order?.id) ? (
                    <div className="flex flex-row gap-1">
                      <span
                        className="cursor-pointer text-brand-4"
                        onClick={() => {
                          if (order?.id)
                            setOrdersShouldShowFull([
                              ...ordersShouldShowFull,
                              order?.id,
                            ]);
                        }}
                      >{`Xem thêm`}</span>
                      <div className="-ml-[5px] h-5 w-5">
                        <Lottie
                          autoplay
                          width={4}
                          height={4}
                          loop
                          animationData={require("assets/dropdown_lottie.json")}
                        />
                      </div>
                    </div>
                  ) : (
                    <div />
                  )}
                </div>
                <div className="flex flex-row justify-end gap-2 py-1 pt-3">
                  <button
                    onClick={() => {
                      if (order?.id) {
                        navigate("/order-detail", {
                          state: { order: order },
                        });
                      }
                    }}
                    className="rounded-[40px] border-[1px] border-solid border-brand-4 px-4 py-1 text-brand-4"
                  >
                    Xem chi tiết
                  </button>
                  {/* <button className="rounded-[40px] bg-brand px-4 py-1 text-white">
                    Đánh giá
                  </button> */}
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
}

export default OrderHistoryPage;
