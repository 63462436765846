import { formatCurrency } from "../../utils/ConcurencyUtils";
import { useLanguage } from "../../hooks/useLanguage";

export interface TotalPriceSectionProps {
  className?: string;
  total?: number;
  subTotal?: number;
  discount?: string;
}

const Divide = () => <div className={`h-[1px] w-full bg-divide`} />;

export default function TotalPriceSectionInOrderDetail(
  props: TotalPriceSectionProps,
) {
  const {
    total = 0,
    subTotal = 0,
    discount = "",
  } = props || {};
  const { t } = useLanguage();

  return (
    <div
      className={`flex flex-col rounded-lg bg-white p-4 ${props.className || ""}`}
    >
      <div>
        <p className={`text-16px font-bold text-brand-4`}>
          {t("totalSection")}
        </p>
      </div>
      <div>
        <div className="flex flex-row justify-between py-2">
          <p className={`text-14px font-normal text-tertiary`}>
            {t("inMoney")}
          </p>
          <p>{formatCurrency(subTotal)}</p>
        </div>
        <Divide />
        <div className={`"py-2" flex flex-row justify-between`}>
          <p className={`text-14px font-normal text-tertiary`}>
            {`Giảm giá`}
          </p>
          <div className={"flex flex-row items-center justify-between"}>
            {`-${formatCurrency(+discount)}`}
          </div>
        </div>
        <Divide />
        <div className="flex flex-row justify-between pt-2">
          <p className={`text-14px font-semibold text-brand-4`}>
            {t("payment")}
          </p>
          <p className={`text-14px font-semibold text-brand-1`}>
            {formatCurrency(total)}
          </p>
        </div>
      </div>
    </div>
  );
}
