import { DELIVERY_METHOD, PAYMENT_METHOD } from "helpers/constants";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hook";

import AddressSection from "components/order/AddressSection";
import { Gap } from "../../components/gap/Gap";
import { Helmet } from "react-helmet";
import MyOrderSection from "../../components/order/MyOrderSection";
import PaymentMethodSection from "components/order/PaymentMethodSection";
import TableSection from "../../components/order/TableSection";
import TotalPriceSection from "../../components/order/TotalPriceSection";
import { addNote } from "store/orderSlice";
import { calculateItemTotalPrice } from "utils/OrderUtils";
import { debounce } from "lodash";
import { s3ImageUrl } from "utils/FileUtils";
import { setSessionId } from "store/sessionSlice";
import { useAuthContext } from "contexts/AuthContext";
import { useLanguage } from "hooks/useLanguage";
import { v4 as uuidv4 } from "uuid";

const OrdersPage: React.FC = () => {
  const currentOrder = useAppSelector((root) => root.order.currentOrder);
  const allItems = useAppSelector((root) => root.allItems.allItems);
  const { t } = useLanguage();
  const dispatch = useAppDispatch();
  const { isAuthenticated: isLogin } = useAuthContext();
  const totalPrice = calculateItemTotalPrice(
    currentOrder?.items,
    allItems,
    isLogin,
  );

  useEffect(() => {
    // Reset session id when order is empty
    if (currentOrder?.items?.length && currentOrder?.items?.length === 0) {
      dispatch(setSessionId(uuidv4()));
    }
  }, [currentOrder?.items?.length]);

  const deliveryMethod = useAppSelector((state) => state.order.deliveryMethod);

  const onChangeNote = debounce((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(addNote(e.target.value));
  }, 1000);

  const originalPrice = currentOrder?.items?.reduce((acc, item) => {
    const itemDetail = allItems?.[item.item_id];
    const price =
      itemDetail?.pricing?.find((p) => p.size === item.size)?.value ||
      item.price;
    const topppingPrice = item?.children?.reduce((acc, topping) => {
      const toppingDetail = allItems?.[topping.item_id];
      return (
        acc + toppingDetail?.pricing?.find((p) => p.size === "small")?.value ||
        topping.price
      );
    }, 0);
    return acc + (price + topppingPrice) * item.quantity;
  }, 0);

  return (
    <div className="h-full w-screen max-w-screen-md bg-default-1 p-4">
      <Helmet>
        <meta name="theme-color" content={"#fff"} />
      </Helmet>

      {currentOrder?.items?.length === 0 ? (
        <div className="flex flex-col items-center justify-center">
          <Gap size={"L"} />
          <img
            src={s3ImageUrl("san_empty.png")}
            alt=""
            className="mx-auto w-1/2 md:w-1/3"
          />
          <Gap size={"M"} />
          <p className="text-14px font-semibold text-brand-4">{t("noOrder")}</p>
          <Gap size={"XXS"} />
          <p className="text-center text-12px font-normal text-gray-menu">
            {t("emptyStateDescription")}
          </p>
        </div>
      ) : (
        <div>
          <AddressSection />
          <Gap size={"S"} />
          <MyOrderSection items={currentOrder?.items} />
          <Gap size={"S"} />
          <TotalPriceSection
            originalPrice={originalPrice}
            total={totalPrice}
            subTotal={totalPrice}
          />
          <Gap size={"S"} />
          {deliveryMethod !== DELIVERY_METHOD.DELIVERY && (
            <PaymentMethodSection />
          )}
          <Gap size={"M"} />
          <div className="">
            <p className={`text-16px font-bold text-brand-4`}>{"Ghi chú"}</p>
            <Gap size={"XS"} />
            <textarea
              name=""
              id=""
              onChange={onChangeNote}
              style={{
                WebkitTextSizeAdjust: "120%",
              }}
              placeholder="Nhập ghi chú"
              className="min-h-[100px] w-full rounded-lg p-2 placeholder:text-tertiary focus:border-[1px] focus:border-solid focus:border-brand-1 focus:outline-none"
            />
          </div>
          <Gap size={"XL"} />
        </div>
      )}
    </div>
  );
};

export default OrdersPage;
