import { Pricing, Product, SelectedTopping, SizeType } from "../models/Product";

import { calculateDiscountedPrice } from "./OrderUtils";

export const formatCurrency = (
  amount: number,
  currency = "VND",
  locale = "it-IT",
) => {
  const formatter = new Intl.NumberFormat(locale, {
    currency: currency,
    style: "currency",
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  return formatter.format(amount);
};

export const calculateItemTotalPrice = (
  item: Product,
  selectedSize: SizeType,
  selectedToppings: SelectedTopping[],
  isLogin: boolean = false,
  isFromTarot: boolean = false,
) => {
  const { pricing } = item || {};

  const price = pricing?.find?.((item) => item.size === selectedSize);
  const selectedPrice = calculateDiscountedPrice(
    isLogin,
    isFromTarot,
    price as Pricing,
  );

  const toppingTotalPrice =
    selectedToppings?.reduce<number>((result, toppingItem) => {
      const selectedToppingPrice = calculateDiscountedPrice(
        isLogin,
        isFromTarot,
        toppingItem as Pricing,
      );
      return result + selectedToppingPrice;
    }, 0) || 0;

  return selectedPrice + toppingTotalPrice;
};
