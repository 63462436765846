import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface PointState {
  points: number;
}

const initialState: PointState = {
  points: 0,
};

const pointSlice = createSlice({
  name: "point",
  initialState,
  reducers: {
    earnPoints: (state, action: PayloadAction<number>) => {
      state.points += action.payload;
    },
    redeemPoints: (state, action: PayloadAction<number>) => {
      if (state.points >= action.payload) {
        state.points -= action.payload;
      }
    },
  },
});

export const { earnPoints, redeemPoints } = pointSlice.actions;
export default pointSlice.reducer;
