import {
  CreateItemFavoriteRequestParams,
  CreateItemFavoriteResponse,
  CreateItemReviewRequestParams,
  CreateItemReviewResponse,
  CreateOrderRequestParams,
  CreateOrderResponse,
  GetBillResponse,
  GetRewardResponse,
  GetUserPointCurrentResponse,
  ListRewardResponse,
  ListUserPointHistoryResponse,
  QueryBillResponse,
  QueryBillsParams,
  QueryFavoritesParams,
  QueryFavoritesResponse,
  QueryOrdersParams,
  QueryOrdersResponse,
  StartExperienceParams,
  StartExperienceResponse,
  UpdatePaymentRequestParams,
  UpdatePaymentResponse,
  ValidatePaymentResponse,
} from "../type";

import baseAxiosQuery from "./baseAxiosQuery";
import { createApi } from "@reduxjs/toolkit/query/react";
import isDev from "../../utils/Helper";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

export const consumerApi = createApi({
  reducerPath: "consumerApi",
  baseQuery: baseAxiosQuery({
    baseUrl: `${process.env.REACT_APP_CONSUMER_BASE_URL}/v1` || "",
  }),
  tagTypes: ["favorites"],
  endpoints: (builder) => ({
    getMe: builder.query<any, void>({
      query: () => ({
        url: "/get_me",
        method: "POST",
        data: {
          jsonrpc: "2.0",
          id: uuidv4(),
          method: "get_me",
        },
      }),
    }),
    createOrder: builder.mutation<
      CreateOrderResponse,
      CreateOrderRequestParams
    >({
      query: (requestParams) => ({
        url: "/create_bill",
        method: "POST",
        data: {
          jsonrpc: "2.0",
          id: uuidv4(),
          method: "create_bill",
          params: {
            order: requestParams.order,
            locale: requestParams.locale,
            is_local: isDev(),
            local_url: "http://localhost:3000/payment-result",
          },
        },
      }),
      // remove "id" and "jsonrpc" from original response
      transformResponse: (baseQueryReturnValue: any) =>
        baseQueryReturnValue?.result,
    }),
    getBill: builder.mutation<GetBillResponse, string>({
      query: (billId) => ({
        url: "/get_bill",
        method: "POST",
        data: {
          jsonrpc: "2.0",
          id: uuidv4(),
          method: "get_bill",
          params: {
            id_: billId,
          },
        },
      }),
    }),
    updatePayment: builder.mutation<
      UpdatePaymentResponse,
      UpdatePaymentRequestParams
    >({
      query: (requestParams) => ({
        url: "/update_payment",
        method: "POST",
        data: {
          jsonrpc: "2.0",
          id: uuidv4(),
          method: "update_payment",
          params: {
            params: requestParams,
          },
        },
      }),
    }),
    queryFavorite: builder.query<QueryFavoritesResponse, QueryFavoritesParams>({
      query: (requestParams) => ({
        url: "/query_favorites",
        method: "POST",
        data: {
          jsonrpc: "2.0",
          id: uuidv4(),
          method: "query_favorites",
          params: requestParams,
        },
        timeout: 30000,
      }),
      providesTags: ["favorites"],
    }),
    queryOrders: builder.query<QueryOrdersResponse, QueryOrdersParams>({
      query: (requestParams) => ({
        url: "/query_orders",
        method: "POST",
        data: {
          jsonrpc: "2.0",
          id: uuidv4(),
          method: "query_orders",
          params: {
            page: requestParams?.page || 0,
            limit: requestParams?.limit || 40,
            asc: requestParams?.asc || false,
            status: requestParams?.status || ["paid"],
            from_date: requestParams?.from_date || "1900-01-01",
            to_date:
              requestParams?.to_date || moment.utc().format("YYYY-MM-DD"),
            zone: requestParams?.zone || "han",
          },
        },
      }),
    }),
    queryBills: builder.query<QueryBillResponse, QueryBillsParams>({
      query: (requestParams) => ({
        url: "/query_bills",
        method: "POST",
        data: {
          jsonrpc: "2.0",
          id: uuidv4(),
          method: "query_bills",
          params: {
            page: requestParams?.page || 0,
            limit: requestParams?.limit || 40,
            order_composite_id: requestParams?.order_composite_id || null,
          },
        },
      }),
    }),
    validatePayment: builder.mutation<ValidatePaymentResponse, string>({
      query: (rawQueryParamString) => ({
        url: "/validate_payment",
        method: "POST",
        data: {
          jsonrpc: "2.0",
          id: uuidv4(),
          method: "validate_payment",
          params: {
            raw: rawQueryParamString,
          },
        },
      }),
    }),
    startAnonymousExperience: builder.mutation<
      StartExperienceResponse,
      StartExperienceParams
    >({
      query: (params) => ({
        url: "/start_anonymous_exp_session",
        method: "POST",
        timeout: 30000, // 30 seconds
        data: {
          jsonrpc: "2.0",
          id: uuidv4(),
          method: "start_anonymous_exp_session",
          params: {
            exp_session: {
              exp_session_id: params?.exp_session_id || "",
              session_id: params.session_id,
              aspect: params?.aspect || "general",
              exp_type: "tarot_short", // default
              context: "", // default
            },
          },
        },
      }),
      transformResponse: (baseQueryReturnValue: any, meta, arg) =>
        baseQueryReturnValue?.result,
    }),
    startExperience: builder.mutation<
      StartExperienceResponse,
      StartExperienceParams
    >({
      query: (params) => ({
        url: "/start_exp_session",
        method: "POST",
        timeout: 30000, // 30 seconds
        data: {
          jsonrpc: "2.0",
          id: uuidv4(),
          method: "start_exp_session",
          params: {
            exp_session: {
              exp_session_id: params?.exp_session_id || "",
              session_id: params.session_id,
              aspect: params.aspect,
              context: params?.context || "",
              exp_type: "tarot_long", // default
            },
          },
        },
      }),
      transformResponse: (baseQueryReturnValue: any, meta, arg) =>
        baseQueryReturnValue?.result,
    }),
    createItemReview: builder.mutation<
      CreateItemReviewResponse,
      CreateItemReviewRequestParams
    >({
      query: (requestParams) => ({
        url: "/create_item_review",
        method: "POST",
        data: {
          jsonrpc: "2.0",
          id: uuidv4(),
          method: "create_item_review",
          params: {
            review: requestParams,
          },
        },
      }),
    }),
    createItemFavorite: builder.mutation<
      CreateItemFavoriteResponse,
      CreateItemFavoriteRequestParams
    >({
      query: (requestParams) => ({
        url: "/create_favorite",
        method: "POST",
        data: {
          jsonrpc: "2.0",
          id: uuidv4(),
          method: "create_favorite",
          params: {
            item_id: requestParams.item_id,
          },
        },
      }),
      invalidatesTags: ["favorites"],
    }),
    removeFavoriteItem: builder.mutation<
      CreateItemFavoriteResponse,
      CreateItemFavoriteRequestParams
    >({
      query: (requestParams) => ({
        url: "/remove_favorite",
        method: "POST",
        data: {
          jsonrpc: "2.0",
          id: uuidv4(),
          method: "remove_favorite",
          params: {
            item_id: requestParams.item_id,
          },
        },
      }),
      invalidatesTags: ["favorites"],
    }),

    // mark payment as cash
    markPaymentAsCash: builder.mutation<any, string>({
      query: (billId) => ({
        url: "/mark_pay_as_cash",
        method: "POST",
        data: {
          jsonrpc: "2.0",
          id: uuidv4(),
          method: "mark_pay_as_cash",
          params: {
            bill_id: billId,
          },
        },
      }),
    }),

    // list reward
    listReward: builder.query<ListRewardResponse, void>({
      query: () => ({
        url: "/list_rewards",
        method: "POST",
        data: {
          jsonrpc: "2.0",
          id: uuidv4(),
          method: "list_rewards",
          params: {
            offset: 0,
            limit: 40,
          },
        },
      }),
    }),

    // get reward
    getReward: builder.query<GetRewardResponse, string>({
      query: (rewardId) => ({
        url: "/get_reward",
        method: "POST",
        data: {
          jsonrpc: "2.0",
          id: uuidv4(),
          method: "get_reward",
          params: {
            id: rewardId,
          },
        },
      }),
    }),

    // get user point current
    getUserPointCurrent: builder.query<GetUserPointCurrentResponse, void>({
      query: () => ({
        url: "/get_user_point_current",
        method: "POST",
        data: {
          jsonrpc: "2.0",
          id: uuidv4(),
          method: "get_user_point_current",
        },
      }),
    }),

    // get list user point transactions
    getUserPointHistory: builder.query<ListUserPointHistoryResponse, void>({
      query: () => ({
        url: "/list_user_point_transactions",
        method: "POST",
        data: {
          jsonrpc: "2.0",
          id: uuidv4(),
          method: "list_user_point_transactions",
          params: {
            offset: 0,
            limit: 40,
          },
        },
      }),
    }),
  }),
});

export const {
  useCreateOrderMutation,
  useUpdatePaymentMutation,
  useValidatePaymentMutation,
  useGetBillMutation,
  useGetMeQuery,
  useLazyGetMeQuery,
  useStartAnonymousExperienceMutation,
  useStartExperienceMutation,
  useCreateItemReviewMutation,
  useCreateItemFavoriteMutation,
  useRemoveFavoriteItemMutation,
  useQueryFavoriteQuery,
  useQueryOrdersQuery,
  useLazyQueryOrdersQuery,
  useLazyQueryFavoriteQuery,
  useMarkPaymentAsCashMutation,
  useLazyQueryBillsQuery,
  useQueryBillsQuery,
  useListRewardQuery,
  useLazyListRewardQuery,
  useGetRewardQuery,
  useLazyGetRewardQuery,
  useGetUserPointCurrentQuery,
  useLazyGetUserPointCurrentQuery,
  useGetUserPointHistoryQuery,
  useLazyGetUserPointHistoryQuery,
} = consumerApi;
