import { Bill, Order } from "store/type";
import { DELIVERY_METHOD, FLAGS, LOCAL_STORAGE_KEYS } from "helpers/constants";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { WithSnackbarProps, withSnackbar } from "hoc/withSnackbar";
import { calculateItemTotalPrice, generateDedupId } from "utils/OrderUtils";
import { useAppDispatch, useAppSelector } from "../store/hook";

import ChevronRight from "assets/icons/chevron-right";
import { ENABLE_HASH_DEDUPID } from "utils/Flag";
import SanButton from "./SanButton";
import ShakingTooltip from "./home-components/ShakingTooltip";
import { createBill } from "store/billSlice";
import { formatCurrency } from "utils/ConcurencyUtils";
import { isNil } from "lodash";
import { s3ImageUrl } from "utils/FileUtils";
import { useAuthContext } from "contexts/AuthContext";
import { useCreateOrderMutation } from "store/api/consumerApi";
import { useLanguage } from "hooks/useLanguage";
import { v4 as uuidv4 } from "uuid";

type BottomTabBarProps = {
  menus: {
    id: number;
    href: string;
    title: string;
    showHeader: boolean;
    icon: (props: any) => JSX.Element;
    showBadge: boolean;
  }[];
};

const BottomTabBar = ({
  menus,
  showSnackbar = () => null,
}: BottomTabBarProps & WithSnackbarProps) => {
  const { isAuthenticated: isLogin } = useAuthContext();
  const location = useLocation();
  const currentOrder = useAppSelector((root) => root.order.currentOrder);
  const allItems = useAppSelector((root) => root.allItems.allItems);
  const tarotResults = useAppSelector((root) => root.tarot.results);

  const totalPrice = calculateItemTotalPrice(
    currentOrder?.items,
    allItems,
    isLogin,
  );
  const orderItem = currentOrder?.items.reduce((acc, current) => {
    return current.quantity + acc;
  }, 0);

  const tableId = localStorage.getItem(LOCAL_STORAGE_KEYS.TABLE_ID);
  const address = localStorage.getItem("address");

  const deliveryMethod = useAppSelector((state) => state.order.deliveryMethod);

  const enableCheckout =
    (deliveryMethod === DELIVERY_METHOD.IN_STORE && !isNil(tableId)) ||
    (deliveryMethod === DELIVERY_METHOD.DELIVERY &&
      !isNil(address) &&
      totalPrice >= 100000);

  const { language, t } = useLanguage();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [
    createOrder,
    { isLoading: isCreatingOrder, isSuccess: isCreateOrderSuccess },
  ] = useCreateOrderMutation();
  const dedupId = ENABLE_HASH_DEDUPID
    ? currentOrder
      ? generateDedupId(currentOrder as Order)
      : ""
    : uuidv4();
  const showCheckout = async () => {
    const orderWithDedupeId = {
      ...currentOrder,
      meta: {
        ...currentOrder?.meta,
        userType: isLogin ? "member" : "anonymous",
      },
      table_id: tableId,
      dedup_id: dedupId,
      note:
        deliveryMethod === DELIVERY_METHOD.DELIVERY
          ? `${currentOrder?.note || ""} \n ${address || ""}`
          : currentOrder?.note || "",
      type: deliveryMethod,
    };
    const createOrderResponse = await createOrder({
      order: orderWithDedupeId as Order,
      locale: language,
    });
    const bill = createOrderResponse.data;
    if (bill) {
      if (bill?.error?.data?.includes("No refresh token available")) {
        showSnackbar({
          message: t("sessionExpired"),
        });
        return;
      }
      if (bill?.error?.code) {
        showSnackbar({
          message: t("sessionExpired"),
        });
      } else {
        dispatch(createBill(bill as Bill));
        navigate("/checkout");
      }
    } else {
      showSnackbar({
        message: t("somethingWentWrong"),
      });
    }
  };

  const shouldShowPaidButton = location.pathname === "/orders";

  const [shouldShowTooltip, setShouldShowTooltip] = useState(true);

  useEffect(() => {
    const showTooltip =
      localStorage.getItem(LOCAL_STORAGE_KEYS.SHOW_TOOLTIP) || "true";
    setShouldShowTooltip(showTooltip === "true" && !shouldShowPaidButton);
  }, [shouldShowPaidButton]);

  return (
    <div className="fixed bottom-4 left-2 right-2 flex h-fit flex-col items-center justify-center gap-2">
      {shouldShowPaidButton && (currentOrder?.items?.length || 0) > 0 && (
        <div className="flex w-full min-w-[350px] max-w-screen-md flex-row items-center justify-center gap-2 px-4">
          <div className="flex w-full flex-row items-center justify-center gap-2 rounded-[40px] border-[1px] border-brand bg-white px-4 py-2 hover:text-brand">
            <span className="text-16px font-semibold text-brand">
              {formatCurrency(totalPrice || 0)}
            </span>
          </div>
          <SanButton
            title={t("continue")}
            onClick={() => {
              if (!enableCheckout) {
                switch (deliveryMethod) {
                  case DELIVERY_METHOD.IN_STORE:
                    showSnackbar({
                      message: t("pleaseSelectTable"),
                    });
                    break;
                  case DELIVERY_METHOD.DELIVERY:
                    if (totalPrice < 100000) {
                      showSnackbar({
                        message: t("min100k"),
                      });
                    } else {
                      showSnackbar({
                        message: t("pleaseSelectAddress"),
                      });
                    }
                    break;
                  default:
                    showSnackbar({
                      message: t("pleaseSelectTableOrAddress"),
                    });
                    break;
                }
              } else {
                showCheckout();
              }
            }}
            className={`${!enableCheckout ? "bg-tertiary hover:bg-tertiary focus:bg-tertiary" : ""}`}
            loading={isCreatingOrder}
            RightIcon={ChevronRight}
          />
        </div>
      )}
      <div className="flex flex-row items-center justify-center gap-2">
        <div className="center flex w-[302px] gap-[8px] rounded-[40px] bg-white px-1 shadow-[0_3px_10px_rgb(0,0,0,0.2)]">
          {menus.map((menu) => (
            <NavBarItem
              key={`nav-item-${menu.id}`}
              badgeCount={orderItem}
              to={menu.href}
              label={menu.title}
              IconComponent={menu.icon}
              showBadge={menu?.showBadge}
              isActive={location.pathname === menu.href}
            />
          ))}
        </div>
        {FLAGS.ENABLE_TAROT && (
          <div
            onClick={() => {
              localStorage.setItem(LOCAL_STORAGE_KEYS.SHOW_TOOLTIP, "false");
              if (tarotResults) {
                navigate("/tarot-result", {
                  state: {
                    tarotResult: tarotResults,
                  },
                });
              } else {
                navigate("/tarot");
              }
            }}
            className="relative flex h-12 w-12 cursor-pointer items-center justify-center rounded-[48px] bg-primary shadow-[0_3px_10px_rgb(0,0,0,0.2)]"
          >
            {shouldShowTooltip && <ShakingTooltip />}
            <img
              src={s3ImageUrl("magic-ball.png")}
              className="h-6 w-6"
              alt=""
            />
          </div>
        )}
      </div>
    </div>
  );
};

type NavItemProps = {
  to: string;
  label: string;
  IconComponent: React.FC<{ className?: string; color: string }>;
  badgeCount?: number;
  showBadge?: boolean;
  isActive?: boolean;
};

const NavBarItem: React.FC<NavItemProps> = ({
  to,
  label,
  IconComponent,
  badgeCount = 0,
  showBadge = false,
  isActive = false,
}) => {
  const itemColor = isActive ? "#8D3D3A" : "#626262";

  return (
    <NavLink
      to={to}
      className={`relative flex w-fit flex-col items-center py-1 transition-all duration-200 ease-in-out`}
    >
      <div
        className={`flex flex-row items-center justify-center rounded-[40px] px-[8px] py-2 transition-all duration-200 ease-in-out
        ${isActive ? "w-[118px] border-[1px] border-primary" : "w-[50px] border-transparent"}
      `}
      >
        <IconComponent color={itemColor} />
        {badgeCount > 0 && showBadge && !isActive && (
          <div
            className={`absolute right-2 top-2 flex h-[14px] w-[14px] flex-row items-center justify-center rounded-full bg-brand-1 pt-[1px]`}
          >
            <p className={`text-[10px] text-white`}>{badgeCount}</p>
          </div>
        )}
        <span
          className={`ml-1 whitespace-nowrap pt-[2px] transition-all duration-200 ease-in-out
          ${isActive ? "translate-y-0 text-primary opacity-100" : "hidden translate-y-2 opacity-0"}`}
        >
          {label}
        </span>
      </div>
    </NavLink>
  );
};

export default withSnackbar(BottomTabBar);
