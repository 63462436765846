import { Carousel, useCarousel } from "nuka-carousel";
import { Swiper, useSwiper } from "swiper/react";

import { Autoplay } from "swiper/modules";
import SwiperCore from "swiper";
import { SwiperSlide } from "swiper/react";
import { isEmpty } from "lodash";
import { s3ImageUrl } from "../../utils/FileUtils";
import { useState } from "react";

SwiperCore.use([Autoplay]);

const TEST_FLAG = true;

export const CustomDots = ({
  totalPages,
  currentPage,
  goToPage,
}: {
  totalPages: number;
  currentPage: number;
  goToPage: (index: number) => void;
}) => {
  const ACTIVE_DOT_COLOR = TEST_FLAG ? "#9F2B2B" : "#9F2B2B";
  const INACTIVE_DOT_COLOR = TEST_FLAG ? "#ECA6AC" : "#fff";
  return (
    <div className="-pt-4 flex w-full flex-row items-center justify-center gap-1">
      {[...Array(totalPages)].map((_, index) => (
        <button
          key={index}
          style={{
            backgroundColor:
              currentPage === index ? ACTIVE_DOT_COLOR : INACTIVE_DOT_COLOR,
            width: currentPage === index ? 24 : 6,
          }}
          onClick={() => goToPage(index)}
          className={`hover:bg-green-200 h-[6px] w-[6px] cursor-pointer rounded-full border-none bg-gray-200 p-0 
         `}
        />
      ))}
    </div>
  );
};

export type BannerProps = {
  className?: string;
  isShowLogo?: boolean;
  borderRadius?: number;
};

export default function BannerSection({
  className,
  isShowLogo = false,
  borderRadius = 16,
}: BannerProps) {
  const [activeIndex, setActiveIndex] = useState(0);
  const banners = [
    ...(isShowLogo
      ? [
          {
            id: 0,
            image: s3ImageUrl("banner_logo.png"),
            url: "/",
          },
        ]
      : []),
    {
      id: 0,
      image: s3ImageUrl("banner_deli_2.png"),
      url: "https://www.facebook.com/permalink.php?story_fbid=pfbid023Jm1z5CD9DZ2HVutdxdi8PupJhzNZ7mca73Mu9BwdHYCXJQC2kojoof84nkzu7NQl&id=61570157798117",
    },
    {
      id: 2,
      image: s3ImageUrl("banner5.webp"),
      url: "https://www.facebook.com/61570157798117/videos/1648422792428862/?app=fbl",
    },
    {
      id: 1,
      image: s3ImageUrl("banner1.webp"),
      url: "https://www.facebook.com/share/p/1EGiV4fwGk/",
    },
    {
      id: 3,
      image: s3ImageUrl("banner3.webp"),
      url: "https://www.facebook.com/share/p/15N8yJuWrD/",
    },
  ];
  return (
    <div className="relative">
      <Swiper
        effect={"cards"}
        grabCursor={true}
        spaceBetween={8}
        centeredSlides={true}
        watchSlidesProgress={true}
        slidesPerView={1}
        initialSlide={0}
        autoplay={{
          delay: 4000,
          pauseOnMouseEnter: true,
        }}
        loop={true}
        style={{ zIndex: 1 }}
        speed={500}
      >
        {banners.map((banner, index) => (
          <div
            key={index}
            style={{ borderRadius: 40 }}
            className="h-full w-full overflow-hidden"
          >
            <SwiperSlide key={index}>
              {({ isActive }) => {
                isActive && setActiveIndex(index);
                return (
                  <img
                    onClick={() => {
                      if (!isEmpty(banner.url)) {
                        window.open(banner.url, "_blank");
                      }
                    }}
                    style={{
                      width: window.innerWidth,
                      objectFit: "contain",
                      borderRadius: borderRadius,
                    }}
                    src={banner.image}
                    className={`h-[400px] min-h-[130px] rounded-[16px] sm:min-h-[240px]`}
                    alt="banner"
                  />
                );
              }}
            </SwiperSlide>
          </div>
        ))}
        <div
          className={` flex justify-center ${
            TEST_FLAG ? "mt-2" : "absolute bottom-1 left-0 right-0 z-10"
          }`}
        >
          <CustomDots
            totalPages={banners.length}
            currentPage={activeIndex}
            goToPage={(index) => setActiveIndex(index)}
          />
        </div>
      </Swiper>
    </div>
  );
}
