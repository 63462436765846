import { Link, useNavigate } from "react-router-dom";
import { WithSnackbarProps, withSnackbar } from "hoc/withSnackbar";

import AddressSection from "components/order/AddressSection";
import { Gap } from "../../components/gap/Gap";
import MyBillSection from "components/order/MyBillSection";
import { PAYMENT_METHOD } from "helpers/constants";
import PageHeader from "../../components/PageHeader";
import PaymentMethodSection from "../../components/order/PaymentMethodSection";
import PuffLoader from "react-spinners/PuffLoader";
import TotalPriceSection from "../../components/order/TotalPriceSection";
import { formatCurrency } from "../../utils/ConcurencyUtils";
import { isEmpty } from "lodash";
import { useAppSelector } from "../../store/hook";
import { useLanguage } from "../../hooks/useLanguage";
import { useMarkPaymentAsCashMutation } from "store/api/consumerApi";

interface CheckoutPageProps {}

function CheckoutPage(props: CheckoutPageProps & WithSnackbarProps) {
  const { t } = useLanguage();
  const { showSnackbar = () => null } = props;
  const lastBill = useAppSelector((root) => root.bill.bill);
  const { items, charges, id = "", vnp_urls = {} } = lastBill || {};
  const { total = 0, sub_total, discount } = charges || {};

  const paymentMethod = useAppSelector((root) => root.order.paymentMethod);
  const selectedVNPayUrlId =
    useAppSelector((root) => root.order.selectedVNPayUrlId) || "app";
  const selectedVNPayUrl = vnp_urls[selectedVNPayUrlId];
  const navigate = useNavigate();

  const [markPaymentAsCash, { isLoading: isMarkingPaymentAsCash }] =
    useMarkPaymentAsCashMutation();

  const handlePayment = async () => {
    if (paymentMethod === PAYMENT_METHOD.CASH && !isEmpty(id)) {
      const res = await markPaymentAsCash(id);
      if (!res?.data?.error) {
        navigate("/payment-result", {
          state: {
            payment_method: PAYMENT_METHOD.CASH,
          },
        });
      } else {
        showSnackbar({
          message: t("somethingWentWrong"),
        });
      }
    }
  };

  return (
    <div className={`relative flex flex-col items-center`}>
      <PageHeader title={t("payment")} />
      <div className="h-full w-screen max-w-screen-md bg-default-1 p-4">
        <AddressSection isCheckout />
        <Gap size={"S"} />
        <MyBillSection items={items} />
        <Gap size={"S"} />
        <TotalPriceSection
          isFinal
          total={total}
          originalPrice={sub_total}
          subTotal={sub_total}
          discount={`${formatCurrency(+(discount || "0"))}`}
        />
        <Gap size={"S"} />
        <PaymentMethodSection vnp_urls={vnp_urls} shouldShowSelector={false} />
        <Gap size={"XL"} />
      </div>
      <div
        className={`fixed bottom-0 left-0 right-0 flex h-[87px] w-full flex-row justify-center rounded-t-[16px] bg-brand-1 p-4`}
      >
        <div className="flex w-full max-w-screen-md flex-row justify-between sm:px-4">
          <div>
            <p className={`text-12px font-normal text-white`}>
              {t("totalPayment")}
            </p>
            <p className={`text-16px font-bold text-white`}>
              {formatCurrency(total)}
            </p>
          </div>
          {paymentMethod === PAYMENT_METHOD.VNPAY ? (
            <Link
              to={selectedVNPayUrl}
              state={{ payment_method: paymentMethod }}
              rel="noopener noreferrer"
            >
              <button
                className={`h-[35px] w-[150px] rounded-[40px] bg-white px-8 py-[6px]`}
              >
                <p className={`text-14px font-semibold text-brand-1`}>
                  {t("payment")}
                </p>
              </button>
            </Link>
          ) : (
            <button
              onClick={handlePayment}
              className={`flex h-[35px] w-[150px] items-center justify-center rounded-[40px] bg-white px-8 py-[6px]`}
            >
              {isMarkingPaymentAsCash ? (
                <PuffLoader color={"#9F2B2B"} size={24} />
              ) : (
                <p className={`text-14px font-semibold text-brand-1`}>
                  {t("payment")}
                </p>
              )}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default withSnackbar(CheckoutPage);
