import { Gap } from "components/gap/Gap";
import LockIcon from "assets/icons/icon-lock";
import PageHeader from "components/PageHeader";
import { s3ImageUrl } from "utils/FileUtils";
import { useAppSelector } from "store/hook";
import { useAuthContext } from "contexts/AuthContext";
import { useLanguage } from "hooks/useLanguage";
import { useNavigate } from "react-router-dom";

export type TarotTopicProps = {};

export default function TarotTopic(props: TarotTopicProps) {
  const { t } = useLanguage();
  const navigate = useNavigate();
  const { isAuthenticated: isLogin } = useAuthContext();

  const topics = [
    {
      id: 0,
      title: t("general"),
      image: s3ImageUrl("tarot_general_full.png"),
      bgColor: "#9e2a2b",
      type: "general",
      isLock: false,
      header: s3ImageUrl("general_header.webp"),
    },
    {
      id: 1,
      title: t("love"),
      image: s3ImageUrl("tarot_love_full.png"),
      bgColor: "#ce5fb9",
      type: "love",
      isLock: !isLogin,
      header: s3ImageUrl("love_header.webp"),
    },
    {
      id: 2,
      title: t("career"),
      image: s3ImageUrl("tarot_career_full.png"),
      bgColor: "#f9b50b",
      type: "career",
      isLock: !isLogin,
      header: s3ImageUrl("career_header.webp"),
      scale: "scale-[1.4]",
    },
    {
      id: 3,
      title: t("finance"),
      image: s3ImageUrl("tarot_finance_full.png"),
      bgColor: "#397a2a",
      type: "finance",
      isLock: !isLogin,
      header: s3ImageUrl("finance_header.webp"),
      scale: "scale-[0.75] -mt-10",
    },
  ];

  const handleTopicClick = (topicType: string) => {
    const destination = isLogin ? "/tarot-question" : "/tarot-experience";
    navigate(destination, {
      state: { topicType: topicType },
    });
  };

  return (
    <div className="flex h-screen flex-col items-center justify-center bg-[#fce8ce]">
      <div className="flex h-full w-full max-w-screen-md flex-col">
        <PageHeader
          transparent
          statusBarColor="#fce8ce"
          titleColor="#31261D"
          title={t("selectTopic")}
          backColor="#31261D"
        />
        <div className="mt-0 w-full px-6">
          <p className="text-center font-inter text-[14px] font-normal leading-[20px] text-brand-31 md:text-[18px]">
            {t("tarotTopicDescription")}
          </p>
        </div>
        <Gap size="XXS" />
        <div className="flex w-full flex-col items-center px-4">
          {topics.map((topic) => (
            <div
              style={{ backgroundColor: topic.bgColor }}
              key={topic.id}
              onClick={() => {
                if (!topic.isLock) {
                  handleTopicClick(topic.type);
                }
              }}
              className={`relative mt-2.5 h-[120px] w-full sm:h-[180px]
                 ${topic.isLock ? "cursor-not-allowed" : "cursor-pointer"} overflow-hidden rounded-[16px] 
                 pl-6 pt-3 transition-all duration-200
                ease-in-out md:mt-6 md:h-[170px] md:w-5/6 xl:mt-4 xl:h-[170px]
                `}
            >
              <Gap size="S" />
              <span className="whitespace-pre pt-10 font-space text-[32px] font-bold leading-[40px] text-white">
                {topic.title}
              </span>
              <img
                src={topic.header}
                alt=""
                className={`absolute -right-10 top-[20%] h-auto w-[70%] cursor-pointer object-contain xl:h-[170px] ${topic?.scale}`}
              />
              {topic.isLock && (
                <div
                  className={`absolute -bottom-1 left-0 right-0 top-0 flex items-center justify-center bg-black text-center font-inter text-[24px] font-semibold leading-[32px] text-white opacity-70`}
                >
                  <LockIcon />
                </div>
              )}
            </div>
          ))}
          {!isLogin && (
            <div className="mt-2.5 flex flex-row items-center gap-1">
              <span
                onClick={() =>
                  navigate("/login", { state: { source: "/tarot-select-topic" } })
                }
                className="cursor-pointer text-center text-[14px] font-semibold text-brand"
              >
                {t("login")}
              </span>
              <span className="text-center text-[14px] font-normal text-brand-4">
                {t("toUnlockAllTopic").toLocaleLowerCase()}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
