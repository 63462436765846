import {
  DEFAULT_COUNTRY,
  DEFAULT_LOCATION,
  setSession,
  setSessionId,
} from "../../store/sessionSlice";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hook";

import BannerSection from "../../components/home-components/BannerSection";
import { CategoryProduct } from "../../models/Product";
import { DrawerProvider } from "contexts/drawer/drawer.provider";
import { Gap } from "../../components/gap/Gap";
import HomeUtilities from "components/home-components/HomeUtilities";
import Layout from "../../containers/layout/layout";
import LoginToUnlockSection from "../../components/home-components/LoginToUnlockSection";
import { MenuWithCategory } from "../../components/menu/MenuWithCategory";
import { MenuWithCategorySkeleton } from "../../components/menu/MenuWithCategorySkeleton";
import { isEmpty } from "lodash";
import { loginAsGuest } from "utils/AuthUtils";
import { useAuthContext } from "contexts/AuthContext";
import { useLanguage } from "hooks/useLanguage";
import { useMenuData } from "hooks/useMenuData";
import { useRewardPoint } from "hooks/useRewardPoint";
import { useSaveTableFromUrl } from "hooks/useSaveTableFromUrl";
import { useSearchParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

const HomePage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const storedCategories = useAppSelector((root) => root.allItems.categories);
  const [activeCategories, setActiveCategories] = useState(storedCategories);
  const { isAuthenticated: isLogin } = useAuthContext();

  const sessionId = useAppSelector((root) => root.session.session_id);
  const { language } = useLanguage();

  useEffect(() => {
    const country = searchParams.get("c") || DEFAULT_COUNTRY;
    const location = searchParams.get("l") || DEFAULT_LOCATION;
    dispatch(
      setSession({
        country,
        location,
      }),
    );
  }, []);

  useEffect(() => {
    const guestToken = localStorage.getItem("guestToken");
    if (!isLogin && !guestToken) {
      loginAsGuest();
    }
  }, [isLogin]);

  useSaveTableFromUrl();

  useEffect(() => {
    if (!sessionId) {
      dispatch(setSessionId(uuidv4()));
    }
  }, []);

  const [allToppings, setAllToppings] = useState<CategoryProduct>();

  const { categories = [], isLoading } = useMenuData();

  // Remove last categories (toppings)
  const selectableProductsCategories = activeCategories?.filter(
    (category) => category.type !== "topping",
  );

  useEffect(() => {
    if (!isEmpty(categories)) {
      const TOPPING_INDEX = categories?.length - 1;
      const toppings = categories?.[TOPPING_INDEX];
      setAllToppings(toppings);
      setActiveCategories(categories);
    }
  }, [categories]);

  return (
    <DrawerProvider allToppings={allToppings}>
      <Layout>
        <Gap size={"M"} />
        <div className="px-4">
          <BannerSection />
        </div>
        <Gap size={"XS"} />
        <div className="px-4">
          {isLogin ? <HomeUtilities /> : <LoginToUnlockSection />}
        </div>
        <Gap size={"M"} />
        <div className={`flex w-full flex-col gap-6`}>
          {isLoading
            ? Array(3)
                .fill(null)
                .map((item, index) => (
                  <MenuWithCategorySkeleton key={`${index}-menu-item`} />
                ))
            : selectableProductsCategories.map((category, index) => (
                <MenuWithCategory
                  key={`${index}-${category.name}`}
                  categoryName={category.name?.[language]}
                  products={category.items}
                />
              ))}
        </div>
        <Gap size={"XXL"} />
      </Layout>
    </DrawerProvider>
  );
};

export default HomePage;
