import { FLAGS } from "helpers/constants";
import { SanCoin } from "components/SanCoin";
import { s3ImageUrl } from "utils/FileUtils";
import { useLanguage } from "hooks/useLanguage";
import { useNavigate } from "react-router-dom";
import { useProfileItem } from "pages/ProfilePage/useProfileItem";
import { useRewardPoint } from "hooks/useRewardPoint";
type HomeUtilitiesProps = {};

const HomeUtilities = (props: HomeUtilitiesProps) => {
  const { t } = useLanguage();
  const { homeUtilities, rewardPointMenu } = useProfileItem();
  const navigate = useNavigate();
  return (
    <div
      className={`flex ${FLAGS.ENABLE_REWARD_POINT ? "h-fit px-4" : "h-[76px]"} w-full 
      flex-col justify-center rounded-2xl bg-white shadow-[0_8px_30px_rgb(0,0,0,0.12)]`}
    >
      {FLAGS.ENABLE_REWARD_POINT && (
        <div
          onClick={() => {
            navigate(rewardPointMenu?.path || "");
          }}
          className={`flex flex-row items-center justify-between border-b-[1px] border-dashed border-border-1 py-3`}
        >
          <div className="flex flex-row gap-1">
            <img src={s3ImageUrl(`san_medal.png`)} className="h-5 w-5" alt="" />
            <p className="text-14px font-semibold text-brand-4">
              {rewardPointMenu?.name}
            </p>
          </div>
          <div className="flex flex-row items-center gap-0.5">
            <SanCoin size={18} />
            <p className="text-16px font-semibold text-brand">
              {rewardPointMenu?.rightNumber}
            </p>
          </div>
        </div>
      )}
      <div
        className={`flex flex-row ${
          FLAGS.ENABLE_REWARD_POINT ? "justify-around" : "justify-around"
        }`}
      >
        {homeUtilities.map(
          (menu, index) =>
            menu && (
              <div
                onClick={() => {
                  navigate(menu.path);
                }}
                key={`home-utils-${menu.id}-${index}`}
                className={`flex cursor-pointer flex-col items-center justify-center py-3`}
              >
                <img
                  src={s3ImageUrl(menu.icon)}
                  alt=""
                  className="h-[24px] w-[24px]"
                />
                <p className="text-12px font-normal text-gray-menu">
                  {menu.shortName}
                </p>
              </div>
            ),
        )}
      </div>
    </div>
  );
};

export default HomeUtilities;
