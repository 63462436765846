import { earnPoints } from "store/pointSlice";
import { useAppDispatch } from "store/hook";
import { useGetUserPointCurrentQuery } from "store/api/consumerApi";

export const useRewardPoint = () => {
  const dispatch = useAppDispatch();
  const { data: userPointCurrent } = useGetUserPointCurrentQuery();
  dispatch(earnPoints(userPointCurrent?.result?.current_points || 0));
  return userPointCurrent?.result?.current_points || 0;
};
