import { imageWithTimestamp, r2ImageUrl, s3ImageUrl } from "utils/FileUtils";
import { useAppDispatch, useAppSelector } from "../../store/hook";

import HeartIcon from "assets/icons/heart-icon";
import HoverImage from "components/HoverImage";
import HoverVideo from "components/HoverVideo";
import IconPlus from "assets/icons/icon-plus";
import { Product } from "../../models/Product";
import RemoteLottie from "components/RemoteLottie";
import { first } from "lodash";
import { openProductDetail } from "../../store/bottomSheetSlice";
import { useAuthContext } from "contexts/AuthContext";
import { useLanguage } from "../../hooks/useLanguage";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";

export interface MenuProductItemProps {
  product: Product;
  index?: number;
}

export default function MenuProductItem(props: MenuProductItemProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isAuthenticated: isLogin } = useAuthContext();
  const { product, index = 0 } = props;
  const { language } = useLanguage();
  const { image, name, pricing, id, description } = product || {};
  const selectedSize =
    pricing?.find?.((item) => item.size === "medium") || first(pricing);
  const { display = "", member_display = "" } =
    selectedSize as Product["pricing"][0];

  const favoritesInString = useAppSelector(
    (root) => root.allItems.favoritesInString,
  );

  const isFavorite = favoritesInString.includes(id);

  const goToProductDetailPage = () => {
    navigate(`/product/${id}`);
  };

  const currentOrder = useAppSelector((root) => root.order.currentOrder);
  const currentItems = currentOrder?.items || [];

  const numOfVariants = useRef(0);

  const quantity = currentItems.reduce((total, item) => {
    if (item.item_id === id) {
      numOfVariants.current++;
      return item.quantity + total;
    }
    return total;
  }, 0);

  const renderAddButton = () => {
    return (
      <button
        onClick={(e) => {
          e.stopPropagation();
          dispatch(openProductDetail(product));
        }}
        className={`z-0 flex h-7 w-7 flex-row items-center justify-center rounded-full border-[1px] border-solid border-brand-shape-3 bg-brand py-1 hover:scale-105 hover:bg-brand-tint focus:outline-none`}
      >
        <IconPlus color="#fff" />
      </button>
    );
  };

  const renderQuantity = () => {
    return (
      <div
        onClick={(e) => {
          e.stopPropagation();
          dispatch(openProductDetail(product));
        }}
        className={`flex h-7 w-7 flex-row items-center justify-center rounded-full border-[1px] border-solid border-brand-shape-3 bg-white pt-0.5 hover:scale-105 hover:border-brand-shape-3 focus:outline-none`}
      >
        <span className={`text-12px font-normal text-brand-4 hover:text-brand`}>
          {quantity}
        </span>
      </div>
    );
  };

  return (
    <div className=" ml-4 mt-4 w-[148px]">
      <div
        onClick={goToProductDetailPage}
        className="relative h-[168px] w-[148px] cursor-pointer overflow-hidden rounded-[16px]"
      >
        {isFavorite && (
          <button
            className={`absolute left-2 top-2 flex h-8 w-8 flex-col items-center justify-center rounded-full
          ${isFavorite ? "bg-secondary-7" : "bg-white"}`}
            aria-label="close"
          >
            <HeartIcon color={isFavorite ? "#fff" : "#858585"} />
          </button>
        )}

        <img
          src={imageWithTimestamp(image)}
          className={`absolute h-[148px] w-[148px] scale-[0.8] object-fill`}
          alt=""
        />
        <div className="absolute bottom-2 right-2 z-0">
          {quantity > 0 ? renderQuantity() : renderAddButton()}
        </div>
      </div>
      <div
        onClick={goToProductDetailPage}
        className={`mt-2 flex w-full flex-row justify-between`}
      >
        <span
          className={`line-clamp-1 w-3/4 text-14px font-semibold text-brand-4`}
        >
          {name?.[language]}
        </span>
        <span className={`text-14px font-normal text-brand-4`}>
          {isLogin ? member_display : display}
        </span>
      </div>
      <div>
        <span className={`line-clamp-1 text-12px font-normal text-tertiary`}>
          {description?.[language]}
        </span>
      </div>
    </div>
  );
}
