import ChevronRight from "assets/icons/chevron-right";
import { Gap } from "components/gap/Gap";
import PageHeader from "components/PageHeader";
import { SanCoin } from "components/SanCoin";
import { s3ImageUrl } from "utils/FileUtils";
import { useLanguage } from "hooks/useLanguage";
import { useListRewardQuery } from "store/api/consumerApi";
import { useNavigate } from "react-router-dom";
import { useRewardPoint } from "hooks/useRewardPoint";

export const SanRewardsPage = () => {
  const { t } = useLanguage();
  const navigate = useNavigate();
  const totalPoints = useRewardPoint();
  const { data: rewards } = useListRewardQuery();

  return (
    <div className="flex flex-col items-center justify-center">
      <PageHeader title={t("earnPoints")} />
      <div className="flex w-full max-w-screen-md flex-col items-center justify-center px-4">
        <div className="relative flex h-full w-full flex-row items-center justify-between rounded-[16px] bg-brand px-4 py-4 shadow-[rgba(13,_38,_76,_0.19)_0px_9px_20px]">
          <div
            onClick={() => navigate("/point-history")}
            className="ml-4 flex scale-150 flex-row items-center justify-center"
          >
            <img
              src={s3ImageUrl(`white_cup_4x.png`)}
              className="h-auto w-[52px]"
              alt=""
            />
          </div>
          <div className="flex h-full flex-col items-end justify-between">
            <div className="flex flex-row items-center justify-center gap-1">
              <SanCoin size={22} />
              <p className="text-[24px] font-semibold text-white">{`${totalPoints}`}</p>
            </div>

            <Gap size={"M1"} />
            <div
              onClick={() => navigate("/point-history")}
              className="flex cursor-pointer flex-row items-center justify-center"
            >
              <p className="text-14px font-normal text-white">
                {t("pointHistory")}
              </p>
              <ChevronRight width={"14px"} height={"8.5px"} color="#fff" />
            </div>
          </div>
          <div
            onClick={() => navigate("/point-history")}
            className={`absolute bottom-0 right-0 top-0`}
          >
            <img
              src={s3ImageUrl(`san_logo_mark.png`)}
              className="h-full opacity-20"
              alt=""
            />
          </div>
        </div>
        <Gap size={"M"} />
        <div className="flex w-full flex-col items-start justify-center">
          <p className="text-[16px] font-semibold text-brand-4">
            {t("giftExchange")}
          </p>
        </div>
        <Gap size={"M"} />
        <div className="flex w-full flex-col items-center justify-center gap-4">
          {rewards?.result.map((gift) => (
            <div className="flex w-full flex-col items-center justify-center overflow-hidden rounded-lg shadow-[rgba(13,_38,_76,_0.19)_0px_9px_20px]">
              <img src={gift.image} alt="" />
              <div className="flex w-full flex-col items-start justify-between px-4 py-2">
                <p className="text-[16px] font-semibold text-brand-4">
                  {gift.name}
                </p>
                <div className="flex flex-row items-center justify-center gap-1">
                  <SanCoin size={16} />
                  <p className="text-[16px] font-normal text-brand-4">
                    {gift.point_cost.toLocaleString("it-IT")}
                  </p>
                </div>
                <Gap size={"XS"} />
                {/* <div className="flex flex-row items-center justify-center gap-1">
                  <p className="text-[14px] font-normal text-brand-4">
                    {gift.claim.length > 1
                      ? "Nhận tại website hoặc tới cửa hàng"
                      : gift.claim[0] === "instore"
                        ? "Nhận trực tiếp tại cửa hàng"
                        : "Nhận tại website"}
                  </p>
                </div> */}
              </div>
            </div>
          ))}
        </div>
        <Gap size={"L"} />
      </div>
    </div>
  );
};
