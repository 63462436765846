import { Button, Form, Input, Select } from "antd";
import React, { useEffect, useState } from "react";

import PageHeader from "components/PageHeader";
import SanButton from "components/SanButton";
import { isEmpty } from "lodash";
import { useAuthContext } from "../../contexts/AuthContext";
import { useLazyGetMeQuery } from "store/api/consumerApi";
import { useNavigate } from "react-router-dom";

const SelectAddress: React.FC = () => {
  const { isAuthenticated: isLogin } = useAuthContext();
  const [getMe, { data: userInfo }] = useLazyGetMeQuery();
  const { phone_number = "" } = userInfo?.result || {};
  useEffect(() => {
    if (isLogin) {
      getMe();
    }
  }, [isLogin]);

  const deliveryWards = [
    {
      id: 0,
      name: "phường Văn Quán (quận Hà Đông)",
    },
    {
      id: 2,
      name: "phường Hạ Đình (quận Thanh Xuân)",
    },
    {
      id: 3,
      name: "phường Thanh Xuân Nam (quận Thanh Xuân)",
    },
    {
      id: 4,
      name: "phường Khương Đình (quận Thanh Xuân)",
    },
    {
      id: 5,
      name: "phường Thanh Xuân Bắc (quận Thanh Xuân)",
    },
    {
      id: 6,
      name: "xã Tân Triều (huyện Thanh Trì)",
    },
  ];

  const navigate = useNavigate();
  const [selectedWard, setSelectedWard] = useState<string>(
    deliveryWards[0].name,
  );
  const [address, setAddress] = useState<string>("");
  const [phone, setPhone] = useState<string>("");

  useEffect(() => {
    setPhone(phone_number?.replace("+84", "0") || "");
    form.setFieldsValue({
      phone: phone_number?.replace("+84", "0") || "",
    });
  }, [phone_number]);

  const [form] = Form.useForm();

  const handleSave = async () => {
    try {
      await form?.validateFields?.();
      const fullAddress = {
        ward: deliveryWards.find((w) => w.name === selectedWard)?.name,
        address: address,
      };
      const addressInString = `${fullAddress.address}, ${fullAddress.ward} \n ${form.getFieldValue("phone")}`;
      localStorage.setItem("address", addressInString);
      navigate(-1);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="flex items-center justify-center bg-white">
      <div className="w-screen max-w-screen-md">
        <PageHeader backUrl="/orders" title="Chọn địa chỉ" />
        <div className="px-4">
          <Form form={form} layout="vertical" onFinish={handleSave}>
            <Form.Item label="Chọn Xã/Phường">
              <Select
                style={{
                  WebkitTextSizeAdjust: "120%",
                }}
                size="large"
                placeholder="Chọn Xã/Phường"
                value={selectedWard}
                onChange={setSelectedWard}
                options={deliveryWards.map((w) => ({
                  value: w.name,
                  label: w.name,
                }))}
              />
              <span className="mt-1 flex flex-row gap-1 text-[12px] text-brand-4">
                * Không tìm thấy xã/phường của bạn?
                <span
                  onClick={() => {
                    window.open(
                      "https://shopeefood.vn/ha-noi/san-cafe",
                      "_blank",
                    );
                  }}
                  className="text-brand"
                >
                  Bấm vào đây
                </span>
              </span>
            </Form.Item>
            <Form.Item
              label="Địa chỉ cụ thể"
              name="address"
              validateTrigger={["onSubmit"]}
              rules={[{ required: true, message: "Vui lòng nhập địa chỉ" }]}
            >
              <Input
                style={{
                  WebkitTextSizeAdjust: "120%",
                }}
                placeholder="Nhập số nhà, tên đường..."
                value={address}
                className="rounded-lg border border-gray-300 p-2 text-[14px]"
                onChange={(e) => setAddress(e.target.value)}
              />
            </Form.Item>

            <Form.Item
              label="Số điện thoại người nhận"
              name="phone"
              rules={[
                { required: true, message: "Vui lòng nhập số điện thoại" },
                {
                  pattern: /^(0[2-9])[0-9]{8}$/,
                  message: "Số điện thoại không hợp lệ",
                },
              ]}
              validateTrigger={["onSubmit"]}
            >
              <Input
                style={{
                  WebkitTextSizeAdjust: "120%",
                }}
                placeholder="Nhập số điện thoại người nhận"
                value={phone}
                className="rounded-lg border border-gray-300 p-2"
                onChange={(e) => setPhone(e.target.value)}
              />
            </Form.Item>

            <Form.Item validateTrigger={["onSubmit"]}>
              <SanButton
                title="Lưu lại"
                className={`${isEmpty(address) ? "bg-tertiary hover:bg-tertiary focus:bg-tertiary" : ""}`}
                onClick={handleSave}
              />
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default SelectAddress;
