import { OrderItem } from "../../store/type";
import { generateCustomizeDescription } from "utils/OrderUtils";
import { imageWithTimestamp } from "utils/FileUtils";
import { useAppSelector } from "../../store/hook";
import { useLanguage } from "hooks/useLanguage";
import { useMenuData } from "hooks/useMenuData";

export interface OrderItemProps {
  item: OrderItem;
  showDivide: boolean;
}

export default function OrderHistoryItem(props: OrderItemProps) {
  const { item, showDivide } = props || {};
  const { children, quantity, id, item_id, size } = item || {};
  const allItems = useAppSelector((root) => root.allItems.allItems);
  const fullInfoItem = allItems?.[item_id];
  const { name, image } = fullInfoItem || {};
  const isOneSize = fullInfoItem?.pricing?.length === 1;
  const { t, language } = useLanguage();
  const {} = useMenuData();

  const description = generateCustomizeDescription(item, isOneSize, t);

  const toppings = children
    .map((item) => {
      const { item_id } = item;
      const fullInfoTopping = allItems?.[item_id];
      const { name } = fullInfoTopping || {};
      return `${name?.[language] || ""}`;
    })
    .join("\n• ");

  return (
    <div>
      <div className={`mt-2 flex flex-row items-start justify-between`}>
        <div className={`flex flex-row items-start`}>
          <img
            src={imageWithTimestamp(image)}
            alt=""
            className={`mt-1 h-14 min-w-14 rounded-lg`}
          />
          <div className={`ml-2 flex min-h-14 flex-col items-start`}>
            <p className={`line-clamp-1 text-14px font-normal text-brand-4`}>
              {`${name?.[language] || ""}`}
            </p>
            <p className={`line-clamp-1 text-12px font-normal text-tertiary`}>
              {description}
            </p>
            {children?.length > 0 && (
              <p
                className={`whitespace-pre-line text-12px font-normal text-tertiary`}
              >
                {` • ${toppings}`}
              </p>
            )}
          </div>
        </div>
      </div>
      {showDivide && <div className={`mt-2 h-[1px] w-full bg-[#FAFAFA]`} />}
    </div>
  );
}
