import { Gap } from "../../components/gap/Gap";
import Markdown from "react-markdown";
import PageHeader from "components/PageHeader";
import React from "react";
import { s3ImageUrl } from "utils/FileUtils";
import { useLanguage } from "hooks/useLanguage";

const markdown = `
## 1. Phương thức giao hàng

Sắn Cafe cung cấp hai phương thức giao hàng chính:  

1. **Mua trực tiếp tại cửa hàng:** Khách hàng có thể đến mua hàng trực tiếp tại các địa điểm của Sắn Cafe.  
2. **Giao hàng tận nơi:** Chúng tôi cung cấp dịch vụ giao hàng đến địa chỉ khách hàng yêu cầu.  

---

## 2. Thời hạn giao hàng

### Thời gian xử lý đơn hàng
- Sau khi nhận được thông tin đặt hàng, chúng tôi sẽ xử lý trong vòng **30 phút** và phản hồi thông tin về việc thanh toán cũng như thời gian giao nhận.  
- Thời gian giao hàng thường trong vòng **30 phút kể từ khi chốt đơn hàng** hoặc theo thỏa thuận với khách hàng.

### Trường hợp giao hàng kéo dài
Một số tình huống bất khả kháng có thể khiến thời gian giao hàng kéo dài hơn dự kiến, bao gồm:
- Không liên lạc được với khách hàng qua điện thoại.  
- Địa chỉ giao hàng không chính xác hoặc khó tìm.  
- Số lượng đơn hàng tăng đột biến, gây quá tải cho hệ thống xử lý.  
- Đối tác vận chuyển hoặc nhà cung cấp giao hàng chậm hơn dự kiến.

---

## 3. Phí vận chuyển

- **Dịch vụ vận chuyển:** Sắn Cafe sử dụng các đơn vị vận chuyển chuyên nghiệp để giao hàng.  
- **Cước phí:** Phí vận chuyển sẽ được tính dựa trên vị trí giao hàng và khối lượng đơn hàng. Mức phí cụ thể sẽ được thông báo cho khách hàng khi xác nhận đơn hàng.

---

## 4. Giới hạn địa lý và giao nhận đặc biệt

- **Khách hàng tỉnh hoặc mua số lượng lớn:** Các đơn hàng lớn hoặc mua sỉ sẽ được vận chuyển qua dịch vụ của các công ty giao nhận. Phí vận chuyển sẽ được tính theo mức phí của các đơn vị cung cấp dịch vụ hoặc theo thỏa thuận hợp đồng giữa hai bên.

---

## 5. Trách nhiệm và chứng từ giao nhận

### Đóng gói và thông tin đơn hàng
- Tất cả đơn hàng đều được đóng gói cẩn thận và niêm phong bởi Sắn Cafe.  
- Thông tin trên bao bì bao gồm:
  - Tên người nhận, số điện thoại, và địa chỉ giao hàng.  
  - Mã vận đơn của đơn hàng.  
- Hóa đơn tài chính hoặc phiếu xuất kho (nếu có) sẽ được gửi kèm trong kiện hàng để đảm bảo tính hợp lệ và hỗ trợ xử lý khiếu nại.

### Trách nhiệm đối với hàng hóa
- Sắn Cafe chịu trách nhiệm giải quyết các vấn đề phát sinh nếu hàng hóa bị hư hỏng trong quá trình vận chuyển.  
- Khách hàng có quyền từ chối nhận sản phẩm và yêu cầu đổi trả theo chính sách "Đổi trả và Hoàn phí."  
- Mọi tranh chấp với bên vận chuyển sẽ do Sắn Cafe làm việc trực tiếp với đối tác để đền bù.

---

## 6. Xử lý chậm trễ

Trong trường hợp giao hàng chậm trễ, Sắn Cafe sẽ thông báo kịp thời cho khách hàng. Khách hàng có quyền lựa chọn:
- **Hủy đơn hàng:** Hoàn tiền theo quy định.  
- **Tiếp tục chờ hàng:** Thỏa thuận thời gian giao hàng mới với Sắn Cafe.

---

Mọi thắc mắc hoặc khiếu nại liên quan đến giao hàng, vui lòng liên hệ:

- **Công ty TNHH Sắn Cafe**  

- **Địa chỉ:** Căn SH36, Khu nhà ở Rue de Charme, Dự án Khu đô thị mới Hạ Đình, Ngõ 214
Nguyễn Xiển, Xã Tân Triều, Huyện Thanh Trì, Thành phố Hà Nội, Việt Nam

- **Email:** contact@san.cafe

- **Website:** [san.cafe](https://san.cafe)

- **Hotline:** 1900 8643

Chúng tôi luôn sẵn sàng hỗ trợ để mang đến trải nghiệm tốt nhất cho khách hàng.

`;

const ShippingPolicyPage: React.FC = () => {
  const { t } = useLanguage();
  return (
    <div className={`flex h-full sm:h-screen w-full flex-col items-center pb-20`}>
      <div
        style={{ height: "calc(100% - 64px)" }}
        className="relative h-full w-full max-w-screen-md overflow-visible"
      >
        <img
          src={s3ImageUrl("bg_san_no_logo.webp")}
          alt=""
          className={`z-0 h-full w-full`}
        />
        <div
          className="absolute min-h-[320%] sm:min-h-[70%] bottom-0 left-0 right-0 top-0 z-0"
        >
          <PageHeader transparent title={t("shippingPolicy")} />
          <div
            style={{ height: "calc(100% - 64px)" }}
            className="mx-4 mt-20 flex h-full flex-col items-center rounded-t-2xl bg-white p-4 md:mx-8"
          >
            <Gap size={"S"} />
            <img
              src={s3ImageUrl("san-logo.png")}
              alt=""
              className="h-12 w-12"
            />
            <Markdown
              components={{
                h1(props) {
                  const { node, ...rest } = props;
                  return (
                    <h1 className={`text-3xl my-3 text-brand-4`} {...rest} />
                  );
                },
                h2(props) {
                  const { node, ...rest } = props;
                  return (
                    <h2 className={`my-1 text-18px text-brand-4`} {...rest} />
                  );
                },
                h3(props) {
                  const { node, ...rest } = props;
                  return (
                    <h3 className={`text-lg my-2 text-brand-4`} {...rest} />
                  );
                },
                ul(props) {
                  const { node, ...rest } = props;
                  return (
                    <span
                      style={{ color: "#31261D" }}
                      className="text-justify text-[14px]"
                      {...rest}
                    />
                  );
                },
                p(props) {
                  const { node, ...rest } = props;
                  return (
                    <span
                      style={{ color: "#31261D" }}
                      className="text-justify text-[14px]"
                      {...rest}
                    />
                  );
                },
                a(props) {
                  const { node, ...rest } = props;
                  return props.href?.includes("@") ? (
                    <a
                      href={`mailto:${props.href}`}
                      style={{ color: "deepskyblue" }}
                      {...rest}
                    />
                  ) : (
                    <a
                      href={`${props.href}`}
                      style={{ color: "deepskyblue" }}
                      {...rest}
                    />
                  );
                },
              }}
              className={`mt-6 text-brand-4 xl:mt-8`}
            >
              {markdown}
            </Markdown>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShippingPolicyPage;
