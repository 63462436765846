import { ClassValue, clsx } from "clsx";
import { OTPInput, SlotProps } from "input-otp";
import { WithSnackbarProps, withSnackbar } from "hoc/withSnackbar";
import { useAppDispatch, useAppSelector } from "store/hook";
import { useLocation, useNavigate } from "react-router-dom";

import { Gap } from "components/gap/Gap";
import PageHeader from "components/PageHeader";
import PuffLoader from "react-spinners/PuffLoader";
import axios from "axios";
import { consumeCode } from "supertokens-web-js/recipe/passwordless";
import { s3ImageUrl } from "utils/FileUtils";
import { setLogin } from "store/authSlice";
import { twMerge } from "tailwind-merge";
import { useHandleLogin } from "hooks/useHandleLogin";
import { useLanguage } from "hooks/useLanguage";
import { useState } from "react";

type OtpVerificationPageProps = {};

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

function OtpVerificationPage(
  props: OtpVerificationPageProps & WithSnackbarProps,
) {
  const { showSnackbar = () => null } = props;
  const { t } = useLanguage();
  const { state } = useLocation();
  const { source = "" } = state || {};
  const [isLoading, setIsLoading] = useState(false);
  const { verifyOtp, resendOtp } = useHandleLogin({
    showSnackbar,
    setIsLoading,
    source,
  });
  console.log("🚀 ~ lamnn ~ source:", source);
  function Slot(props: SlotProps) {
    return (
      <div
        className={cn(
          "relative h-12 w-12 bg-white text-[2rem]",
          "flex items-center justify-center",
          "transition-all duration-300",
          "rounded-lg border border-solid border-border-1",
          "group-hover:border-accent-foreground/20 group-focus-within:border-accent-foreground/20",
          "outline-accent-foreground/20 outline outline-0",
          {
            "outline-accent-foreground outline-1 outline-[#0B92D6]":
              props.isActive,
          },
        )}
      >
        <div className="">
          <p className="bg-white text-center text-24px font-bold text-[#303F3C]">
            {props.char ?? props.placeholderChar}
          </p>
        </div>
      </div>
    );
  }

  const [otp, setOtp] = useState("");

  return (
    <div className="flex h-screen w-full flex-col items-center bg-white">
      <div className="relative h-full w-full max-w-screen-md overflow-hidden bg-[#FFFAF3]">
        <div className="absolute z-20">
          <PageHeader transparent />
        </div>
        <img
          src={s3ImageUrl("otp_water_mark.png")}
          className="absolute left-0 top-0 w-[195px] opacity-80"
          alt=""
        />
        <div className="flex w-full flex-col items-center justify-center px-4 pt-28">
          <img
            src={s3ImageUrl("san-logo.png")}
            alt=""
            className="z-0 h-12 w-12"
          />
          <Gap size={"S"} />
          <p className="text-center text-16px font-medium text-brand-4">
            {t("inputOtp")}
          </p>
          <Gap size={"XXS"} />
          <p className="px-6 text-center text-12px font-normal text-gray-menu">
            {t("otpDescription")}
          </p>
          <Gap size={"M1"} />
          <OTPInput
            value={otp}
            onChange={setOtp}
            onComplete={async (code) => {
              console.log(code);
              setIsLoading(true);
              verifyOtp(code);
            }}
            maxLength={6}
            render={({ slots }) => (
              <div className="flex flex-row gap-2">
                {slots.map((slot, idx) => (
                  <Slot key={idx} {...slot} />
                ))}
              </div>
            )}
          />
          <Gap size={"M"} />
          <Gap size={"S"} />
          {isLoading ? (
            <PuffLoader color="#9F2B2B" size={32} />
          ) : (
            <div className="flex flex-row items-center justify-center">
              <span className="text-center text-12px font-normal text-gray-menu">
                {t("notReceivedOtp")}
              </span>
              <span
                onClick={() => {
                  setOtp("");
                  resendOtp();
                }}
                className="ml-1 text-center text-12px font-normal text-brand-1"
              >
                {t("resendOtp")}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default withSnackbar(OtpVerificationPage);
