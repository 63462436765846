import React, { useContext, useEffect, useState } from "react";
import { clearGuestToken, clearTokens } from "store/api/baseAxiosQuery";
import { setIsGuest, setLogin, setStInfo } from "store/authSlice";
import { useAppDispatch, useAppSelector } from "store/hook";
import { useGetMeQuery, useLazyGetMeQuery } from "store/api/consumerApi";

import ChevronRight from "assets/icons/chevron-right";
import { Gap } from "components/gap/Gap";
import { GeneralMenuItem } from "./GeneralMenuItem";
import { Helmet } from "react-helmet";
import { NotLoginPage } from "./NotLoginPage";
import SanButton from "components/SanButton";
import Session from "supertokens-web-js/recipe/session";
import { s3ImageUrl } from "utils/FileUtils";
import { useAuthContext } from "contexts/AuthContext";
import { useLanguage } from "hooks/useLanguage";
import { useNavigate } from "react-router-dom";
import { useProfileItem } from "./useProfileItem";

const ProfilePage: React.FC = () => {
  const { t } = useLanguage();
  const dispatch = useAppDispatch();
  const { isAuthenticated: isLogin } = useAuthContext();
  const navigate = useNavigate();
  const [isLogoutLoading, setIsLogoutLoading] = useState(false);
  const { setIsAuthenticated } = useAuthContext();

  const { profileMenus, generalMenus } = useProfileItem();

  const [getMe, { data: userInfo }] = useLazyGetMeQuery();

  useEffect(() => {
    if (isLogin) {
      getMe();
    }
  }, [isLogin]);

  const { phone_number = "", email = "" } = userInfo?.result || {};

  return (
    <div className="h-full w-full overflow-hidden bg-brand">
      <Helmet>
        <meta name="theme-color" content={"#9f2b2b"} />
      </Helmet>

      {/* Profile info */}
      <div className="flex flex-col items-center justify-between p-4 pt-10">
        <img
          src={s3ImageUrl("FAVICON.png")}
          alt=""
          className="h-[72px] w-[72px] rounded-full border-[1.5px] border-solid border-white object-cover"
        />
        <p className="mt-2 text-14px font-bold text-white">
          {isLogin ? t("member") : t("guest")}
        </p>
        {isLogin && (
          <p className="mt-0 text-16px font-semibold text-brand-3">
            {phone_number || email || ""}
          </p>
        )}
        {/* EDIT */}
        {/* {isLogin && (
          <p className="mt-1 text-12px font-semibold text-brand-3 underline underline-offset-2">
            {t("edit")}
          </p>
        )} */}
      </div>
      <div className="h-full w-full rounded-t-[16px] bg-white px-4 py-1">
        <p className="mt-2 text-14px font-semibold text-brand-4">
          {t("account")}
        </p>
        <div className="mt-2 rounded-2xl border-[1px] border-solid border-border-1 px-3">
          {profileMenus.map(
            (menu, index) =>
              menu && (
                <div
                  onClick={() => {
                    navigate(menu.path);
                  }}
                  key={`profile-${menu.id}-${index}`}
                  className={`flex cursor-pointer flex-row items-center justify-between py-3 ${index < profileMenus.length - 1 ? "border-b-[1px]" : ""} border-solid border-border-1`}
                >
                  <div className="flex flex-row items-center">
                    <img
                      src={s3ImageUrl(menu.icon)}
                      alt=""
                      className="h-[24px] w-[24px]"
                    />
                    <p className="ml-2 text-14px font-normal text-brand-4">
                      {menu.name}
                    </p>
                  </div>
                  <div className="flex flex-row items-center gap-2">
                    <div className="flex flex-row items-center gap-0.5">
                      {menu?.RightIcon ? <menu.RightIcon /> : null}
                      {menu?.rightNumber ? (
                        <span className="ml-0 text-14px font-semibold text-brand">
                          {`${menu?.rightNumber}`}
                        </span>
                      ) : null}
                    </div>
                    <ChevronRight color="#9F2B2B" />
                  </div>
                </div>
              ),
          )}
        </div>
        <Gap size={"M"} />
        <p className="mt-2 text-14px font-semibold text-brand-4">
          {t("generalInfo")}
        </p>
        <div className="mt-2 rounded-2xl border-[1px] border-solid border-border-1 px-3">
          {generalMenus.map((menu, index) => (
            <GeneralMenuItem
              key={`general-${menu.id}-${index}`}
              menu={menu as GeneralMenuItem}
              index={index}
              total={generalMenus.length - 1}
            />
          ))}
        </div>
        <Gap size={"M1"} />
        {isLogin ? (
          <SanButton
            title={t("logout")}
            loading={isLogoutLoading}
            onClick={async () => {
              setIsLogoutLoading(true);
              try {
                await Session.signOut();
                dispatch(setStInfo(undefined));
                dispatch(setLogin(false));
                dispatch(setIsGuest(true));
                setIsAuthenticated(false);
                clearGuestToken();
                clearTokens();
              } catch (error) {
                console.log("logout error", error);
              } finally {
                setIsLogoutLoading(false);
              }
            }}
            type="outline"
          />
        ) : (
          <SanButton
            title={t("login")}
            onClick={async () => {
              navigate("/login");
            }}
          />
        )}
        <Gap size={"L"} />
      </div>
    </div>
  );
};

export default ProfilePage;
