import { LOCAL_STORAGE_KEYS } from "helpers/constants";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const isSameDay = (timestamp?: string | null): boolean => {
  if (!timestamp) return false;
  return dayjs(Number(timestamp)).isSame(dayjs(), "day");
};

export const useSaveTableFromUrl = (): void => {
  const [searchParams] = useSearchParams();
  useEffect(() => {
    const table = searchParams.get("tbl") || "";
    const tableRegex = /^(T|S|t|s)?\d{1,2}$/;

    const storedTimestamp = localStorage.getItem(
      LOCAL_STORAGE_KEYS.TABLE_TIMESTAMP,
    );
    if (!isSameDay(storedTimestamp) && isEmpty(table)) {
      localStorage.removeItem(LOCAL_STORAGE_KEYS.TABLE_ID);
      localStorage.removeItem(LOCAL_STORAGE_KEYS.TABLE_TIMESTAMP);
      return;
    }

    if (tableRegex.test(table)) {
      let normalizedTable = table.toUpperCase();

      normalizedTable = normalizedTable.replace(
        /(\D?)(\d+)/,
        (_, prefix, num) => `${prefix}${num.padStart(2, "0")}`,
      );

      localStorage.setItem(LOCAL_STORAGE_KEYS.TABLE_ID, normalizedTable);
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.TABLE_TIMESTAMP,
        Date.now().toString(),
      );
    }
  }, [searchParams]);
};

export const getTableFromStorage = (): string | undefined => {
  const storedTimestamp = localStorage.getItem(
    LOCAL_STORAGE_KEYS.TABLE_TIMESTAMP,
  );
  if (!isSameDay(storedTimestamp)) {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.TABLE_ID);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.TABLE_TIMESTAMP);
    return undefined;
  }
  return localStorage.getItem(LOCAL_STORAGE_KEYS.TABLE_ID) || undefined;
};
