import * as React from "react";
const DiscountIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || 14}
    height={props.height || 14}
    viewBox="0 0 439.03 439.03"
    transform="scale(-1,1)"
    {...props}
  >
    <path
      fill="#9F2B2B"
      d="M151.813 226.521c7.499 7.499 17.351 11.249 27.201 11.249s19.701-3.75 27.2-11.249c7.267-7.266 11.269-16.926 11.269-27.201 0-10.275-4.002-19.936-11.268-27.201-7.266-7.266-16.926-11.268-27.201-11.268s-19.936 4.001-27.202 11.268c-14.998 14.997-14.998 39.402.001 54.402zm10.606-43.797c4.434-4.433 10.327-6.874 16.596-6.874s12.161 2.441 16.595 6.875c4.433 4.432 6.874 10.326 6.874 16.594s-2.441 12.162-6.875 16.595c-9.148 9.149-24.037 9.15-33.189 0h.001c-9.152-9.151-9.152-24.039-.002-33.19zM156.086 293.725c-14.998 14.999-14.998 39.404 0 54.403 7.5 7.5 17.351 11.249 27.201 11.249 9.852 0 19.702-3.75 27.202-11.249 14.998-14.999 14.998-39.404 0-54.403-15.001-15-39.404-14.998-54.403 0zm43.796 43.796c-9.149 9.149-24.037 9.151-33.188 0-9.15-9.15-9.15-24.04 0-33.19 9.148-9.15 24.038-9.151 33.188 0 9.15 9.15 9.15 24.04 0 33.19zM243.56 219.669l-131.666 67.56a7.5 7.5 0 0 0 3.43 14.175 7.474 7.474 0 0 0 3.418-.829l131.666-67.56a7.5 7.5 0 1 0-6.848-13.346z"
    />
    <path
      fill="#9F2B2B"
      d="M433.716 48.512C433.716 21.762 411.953 0 385.204 0H332.88c-26.749 0-48.512 21.762-48.512 48.512v34.124l-70.479-6.933a30.145 30.145 0 0 0-24.362 8.719L11.682 262.267c-8.489 8.49-8.489 22.305 0 30.795l139.591 139.59c4.113 4.113 9.581 6.378 15.397 6.378s11.285-2.265 15.398-6.378l177.845-177.844a30.16 30.16 0 0 0 8.718-24.362l-8.665-88.089 59.542-59.541c9.162-9.163 14.208-21.346 14.208-34.304zm-84.41 195.689L171.461 422.045c-1.279 1.28-2.981 1.985-4.791 1.985s-3.511-.705-4.79-1.984L22.289 282.456a6.784 6.784 0 0 1 0-9.583L200.134 95.029a15.197 15.197 0 0 1 12.286-4.397l71.948 7.077v22.743c-5.916 1.374-11.348 4.365-15.749 8.767-6.21 6.21-9.63 14.466-9.63 23.249s3.42 17.039 9.63 23.248c6.21 6.21 14.466 9.63 23.249 9.63 8.782 0 17.038-3.42 23.248-9.63h-.001c6.211-6.209 9.631-14.466 9.631-23.248s-3.42-17.039-9.63-23.249c-4.401-4.401-9.833-7.392-15.748-8.766V99.184l29.213 2.873c7.226.711 12.985 6.471 13.696 13.696l11.426 116.161a15.211 15.211 0 0 1-4.397 12.287zm-63.777-108.442c3.052 11.178 11.868 19.994 23.046 23.046a17.817 17.817 0 0 1-4.065 6.303h-.001a17.757 17.757 0 0 1-12.641 5.237c-4.776 0-9.266-1.86-12.643-5.237-3.376-3.377-5.236-7.866-5.236-12.642s1.86-9.265 5.237-12.642a17.826 17.826 0 0 1 6.303-4.065zM408.9 72.209l-50.834 50.833-.861-8.758c-1.409-14.326-12.83-25.746-27.155-27.155l-30.682-3.018V48.512C299.368 30.033 314.401 15 332.88 15h52.324c18.479 0 33.512 15.033 33.512 33.512 0 8.952-3.486 17.368-9.816 23.697z"
    />
  </svg>
);
export default DiscountIcon;
