import * as React from "react"
const MediumSize = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props?.width || 42}
    height={props?.height || 70}
    fill="none"
    {...props}
  >
    <path
      stroke={props?.color}
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M28.328 68.616H13.672a5.704 5.704 0 0 1-5.623-4.733L3.04 24.916H38.96L33.95 63.883a5.712 5.712 0 0 1-5.623 4.733Z"
    />
    <path
      stroke={props?.color}
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M39.242 18.118H2.758c-.97 0-1.758.787-1.758 1.758v3.283c0 .97.787 1.758 1.758 1.758h36.484c.97 0 1.758-.787 1.758-1.758v-3.283c0-.97-.787-1.758-1.758-1.758ZM20.996 1.238h-1.083c-9.315 0-16.872 7.557-16.872 16.88H38.96c0-9.323-7.557-16.88-16.88-16.88h-1.083Z"
    />
  </svg>
)
export default MediumSize
