import { Gap } from "components/gap/Gap";
import PageHeader from "components/PageHeader";
import { useGetUserPointHistoryQuery } from "store/api/consumerApi";
import { useLanguage } from "hooks/useLanguage";

export default function PointHistoryPage() {
  const { t } = useLanguage();

  const { data: histories } = useGetUserPointHistoryQuery();

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="flex max-w-screen-md flex-col items-center justify-center">
        <PageHeader title={t("pointHistory")} />
        {histories?.result?.length === 0 && (
          <div className="flex w-full flex-col items-center justify-center gap-2 px-4">
            <p className="mt-4 text-[16px] font-normal text-brand-4">
              {t("noHistory")}
            </p>
          </div>
        )}
        <div className="flex w-full flex-col justify-center gap-2 px-4">
          {histories?.result?.map((item, index) => (
            <div key={item.id} className="flex w-full flex-col">
              <div
                key={item.id}
                className="flex flex-row items-start justify-between"
              >
                <div className="flex flex-col items-start justify-between">
                  <p className="text-[16px] font-semibold text-brand-4">
                    {item.bill_id}
                  </p>
                  <p className="text-[14px] font-normal text-brand-4">
                    {item.created_at}
                  </p>
                </div>
                <div className="flex flex-row items-center justify-between">
                  <p className="flex flex-row items-center gap-1 text-[16px]  text-brand-4">
                    <span
                      className={`${
                        item.points > 0
                          ? "font-semibold text-brand-1"
                          : "text-brand-4"
                      }`}
                    >
                      {item.points > 0 ? "+" : "-"}
                    </span>
                    <span
                      className={`${
                        item.points > 0
                          ? "font-semibold text-brand-1"
                          : "text-brand-4"
                      }`}
                    >
                      {item.points.toLocaleString("it-IT")}
                    </span>
                  </p>
                </div>
              </div>
              {index !== histories?.result?.length - 1 && (
                <div className="mt-2 h-[1px] w-full bg-default-1" />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
