export interface CategoryProduct {
  id: string;
  name: {
    vn: string;
    en: string;
  };
  type?: string;
  items: Product[];
}

export type SizeType = "small" | "medium" | "large";

export interface Product {
  id: string;
  image: string;
  type: string;
  name: {
    vn: string;
    en: string;
  };
  description: {
    vn: string;
    en: string;
  };
  customizable: {
    sugar?: number[];
    ice?: number[];
    hot?: boolean;
    topping?: string[];
  };
  pricing: Pricing[];
  public: boolean;
  tarotCardId?: string;
  activeIndex?: number; // index of tarot card
}

export interface Pricing {
  size: SizeType;
  value: number;
  member_value: number;
  member_display: string;
  display: string;
  exp_value: number;
  exp_display: string;
  exp_pct: number;
  member_pct: number;
  currency: string;
}

export interface SelectedProduct {
  id: string;
  image: string;
  name: string;
  totalPrice: number;
  size: SizeType;
  hot?: boolean;
  ice?: number;
  sugar?: number;
  selectedToppings?: SelectedTopping[];
  quantity: number;
}

export type SelectedTopping = Partial<Pricing> & {
  id: string;
  name: string;
};

export const DEFAULT_CATEGORY: CategoryProduct = {
  id: "",
  name: {
    vn: "",
    en: "",
  },
  items: [],
};

export const convertPercentToContent = (percent: number) => {
  return (
    (percent === 0 && "low") ||
    (percent === 50 && "medium") ||
    (percent === 100 && "high") ||
    ""
  );
};

export const convertContentToPercent = (content: string) => {
  return (
    (content === "low" && 0) ||
    (content === "medium" && 50) ||
    (content === "high" && 100) ||
    0
  );
};

export const convertContentToDisplaySize = (content: SizeType) => {
  return (
    (content === "small" && "S") ||
    (content === "medium" && "M") ||
    (content === "large" && "L") ||
    0
  );
};
