import { StartExperienceResponse } from "./type";
import { createSlice } from "@reduxjs/toolkit";

interface TarotState {
  results: StartExperienceResponse | null;
}

const initialState: TarotState = {
  results: null,
};

const tarotSlice = createSlice({
  name: "tarot",
  initialState,
  reducers: {
    setTarotResults: (state, action) => {
      state.results = action.payload;
    },
    resetTarotResults: (state) => {
      state.results = null;
    },
  },
});

export const { setTarotResults, resetTarotResults } = tarotSlice.actions;

export default tarotSlice.reducer;
