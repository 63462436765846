import { motion } from "framer-motion";
import { s3ImageUrl } from "../../utils/FileUtils";

const cloudImages: string[] = ["bg_cloud_1.webp", "bg_cloud_2.webp"];
const cloudCount: number = 8;

type Cloud = {
  id: number;
  image: string;
  size: number;
  speed: number;
  direction: number;
  top: number;
};

const getRandomValue = (min: number, max: number): number =>
  Math.random() * (max - min) + min;

const generateUniquePositions = (count: number, min: number, max: number): number[] => {
  const step = (max - min) / count;
  const positions: number[] = [];

  for (let i = 0; i < count; i++) {
    const rangeMin = min + i * step;
    const rangeMax = rangeMin + step;
    const newPos = Math.round(getRandomValue(rangeMin, rangeMax));
    positions.push(newPos);
  }
  return positions;
};

const uniqueTops: number[] = generateUniquePositions(cloudCount, 0, 100);

const clouds: Cloud[] = uniqueTops.map((top, index) => ({
  id: index,
  image: cloudImages[index % cloudImages.length],
  size: getRandomValue(120, 220),
  speed: getRandomValue(20, 35),
  direction: index % 2 === 0 ? 1 : -1,
  top,
}));

export default function CloudAnimation(): JSX.Element {
  return (
    <div className="w-screen overflow-hidden">
      {clouds.map(({ id, image, size, speed, direction, top }) => (
        <motion.div
          key={id}
          className="absolute z-[5]"
          style={{ top: `${top}%`, width: `${size}px` }}
          initial={{ x: direction === 1 ? "-120%" : "120%" }}
          animate={{ x: direction === 1 ? "120%" : "-120%" }}
          transition={{
            repeat: Infinity,
            duration: speed,
            ease: "linear",
          }}
        >
          <img
            src={s3ImageUrl(image)}
            alt=""
            className="w-full"
            loading="lazy"
          />
        </motion.div>
      ))}
    </div>
  );
}
