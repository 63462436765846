import { persistReducer, persistStore } from "redux-persist";

import allItemReducer from "./itemSlice";
import authSlice from "./authSlice";
import billReducer from "./billSlice";
import bottomSheetSlice from "./bottomSheetSlice";
import { configureStore } from "@reduxjs/toolkit";
import { consumerApi } from "./api/consumerApi";
import { menuApi } from "./api/menuApi";
import orderReducer from "./orderSlice";
import pointReducer from "./pointSlice";
import { rtkQueryErrorLogger } from "./middleware";
import sessionReducer from "./sessionSlice";
import storage from "redux-persist/lib/storage";
import tarotReducer from "./tarotSlice";

const persistConfig = {
  key: "root",
  storage: storage,
};

const persistedOrderReducer = persistReducer(persistConfig, orderReducer);
const persistedSessionReducer = persistReducer(persistConfig, sessionReducer);
const persistedBillReducer = persistReducer(persistConfig, billReducer);
const persistedAuthReducer = persistReducer(persistConfig, authSlice);
const persistedTarotReducer = persistReducer(persistConfig, tarotReducer);

export const store = configureStore({
  reducer: {
    [menuApi.reducerPath]: menuApi.reducer,
    [consumerApi.reducerPath]: consumerApi.reducer,
    order: persistedOrderReducer,
    session: persistedSessionReducer,
    bill: persistedBillReducer,
    tarot: persistedTarotReducer,
    auth: persistedAuthReducer,
    allItems: allItemReducer,
    bottomSheet: bottomSheetSlice,
    point: pointReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST", "persist/REHYDRATE"],
      },
    }).concat(menuApi.middleware, consumerApi.middleware, rtkQueryErrorLogger),
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
