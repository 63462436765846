import { BillItem, Order, ProductItem } from "../../store/type";

import MyBillItem from "./MyBillItem";
import OrderItem from "./OrderItem";
import { useAppSelector } from "../../store/hook";
import { useLanguage } from "../../hooks/useLanguage";

export interface MyOrderSectionProps {
  className?: string;
  items?: BillItem[];
}

export default function MyBillSection(props: MyOrderSectionProps) {
  const { items = [] } = props;
  const { t } = useLanguage();
  return (
    <div
      className={`center flex flex-col rounded-lg bg-white p-4 ${props.className || ""}`}
    >
      <p className={`text-16px font-bold text-brand-4`}>{t("myOrder")}</p>
      <div>
        {items?.map((item: BillItem, index: number) => (
          <MyBillItem
            key={index}
            item={item}
            showDivide={index < items.length - 1}
          />
        ))}
      </div>
    </div>
  );
}
